export const CREATE_WARZONE_GAME_ROOM_START = 'CREATE_WARZONE_GAME_ROOM_START';
export const CREATE_WARZONE_GAME_ROOM_SUCCESS =
  'CREATE_WARZONE_GAME_ROOM_SUCCESS';
export const CREATE_WARZONE_GAME_ROOM_FAIL = 'CREATE_WARZONE_GAME_ROOM_FAIL';
export const RESET_CREATE_WARZONE_GAME_ROOM = 'RESET_CREATE_WARZONE_GAME_ROOM';

export const SELECT_WARZONE_BOX_START = 'SELECT_WARZONE_BOX_START';
export const SELECT_WARZONE_BOX_SUCCESS = 'SELECT_WARZONE_BOX_SUCCESS';
export const SELECT_WARZONE_BOX_FAIL = 'SELECT_WARZONE_BOX_FAIL';

export const NEXT_WARZONE_LEVEL_START = 'NEXT_WARZONE_LEVEL_START';
export const NEXT_WARZONE_LEVEL_SUCCESS = 'NEXT_WARZONE_LEVEL_SUCCESS';
export const NEXT_WARZONE_LEVEL_FAIL = 'NEXT_WARZONE_LEVEL_FAIL';

export const PREVIOUS_WARZONE_LEVEL_START = 'PREVIOUS_WARZONE_LEVEL_START';
export const PREVIOUS_WARZONE_LEVEL_SUCCESS = 'PREVIOUS_WARZONE_LEVEL_SUCCESS';
export const PREVIOUS_WARZONE_LEVEL_FAIL = 'PREVIOUS_WARZONE_LEVEL_FAIL';

export const FETCH_WARZONE_WEEKS_BEST_SCORE_START =
  'FETCH_WARZONE_WEEKS_BEST_SCORE_START';
export const FETCH_WARZONE_WEEKS_BEST_SCORE_SUCCESS =
  'FETCH_WARZONE_WEEKS_BEST_SCORE_SUCCESS';
export const FETCH_WARZONE_WEEKS_BEST_SCORE_FAIL =
  'FETCH_WARZONE_WEEKS_BEST_SCORE_FAIL';

export const RESET_SELECTED_WARZONE_GAME_ROOM_STORE =
  'RESET_SELECTED_WARZONE_GAME_ROOM_STORE';

export const RESET_WARZONE_GAME_ROOM_STORE = 'RESET_WARZONE_GAME_ROOM_STORE';

export type WarzoneGameRoomActionTypes =
  | typeof CREATE_WARZONE_GAME_ROOM_START
  | typeof CREATE_WARZONE_GAME_ROOM_SUCCESS
  | typeof CREATE_WARZONE_GAME_ROOM_FAIL
  | typeof FETCH_WARZONE_WEEKS_BEST_SCORE_START
  | typeof FETCH_WARZONE_WEEKS_BEST_SCORE_SUCCESS
  | typeof FETCH_WARZONE_WEEKS_BEST_SCORE_FAIL
  | typeof NEXT_WARZONE_LEVEL_START
  | typeof NEXT_WARZONE_LEVEL_SUCCESS
  | typeof NEXT_WARZONE_LEVEL_FAIL
  | typeof PREVIOUS_WARZONE_LEVEL_START
  | typeof PREVIOUS_WARZONE_LEVEL_SUCCESS
  | typeof PREVIOUS_WARZONE_LEVEL_FAIL
  | typeof SELECT_WARZONE_BOX_START
  | typeof SELECT_WARZONE_BOX_SUCCESS
  | typeof SELECT_WARZONE_BOX_FAIL
  | typeof RESET_SELECTED_WARZONE_GAME_ROOM_STORE
  | typeof RESET_CREATE_WARZONE_GAME_ROOM
  | typeof RESET_WARZONE_GAME_ROOM_STORE;
