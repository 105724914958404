import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from '../constants';
import { StoreState } from '../StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Language } from '../../domain/Language';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as languageService from '../../store/language/service';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import Loader from '../../common/Loader/Loader';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { JwtToken } from '../Axios/axios-instance';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { logout, selectLocale } from '../../store/auth/actions';
import { Locale } from '../../domain/Translation';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import * as authorizationCountryService from '../../store/authorizationCountry/service';
// import { useDisconnect } from 'wagmi'; uncomment if wallet login needed
// import WalletConnect from '../../component/WalletConnect/WalletConnect'; uncomment if wallet login needed

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const PublicGameListPage = React.lazy(
  () => import('../../pages/Public/GameListPage/PublicGameListPage'),
);

const PolicyPage = React.lazy(
  () => import('../../pages/Public/PolicyPage/PolicyPage'),
);

const SignUpPage = React.lazy(
  () => import('../../pages/Public/SignUpPage/SignUpPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const CashoutListPage = React.lazy(
  () => import('../../pages/Admin/Cashout/CashoutListPage/CashoutListPage'),
);

const ProfilePage = React.lazy(
  () => import('../../pages/Public/ProfilePage/ProfilePage'),
);

const SingleGame = React.lazy(
  () => import('../../pages/Public/SingleGamePage/SingleGamePage'),
);

const AuthorizationCountryListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/AuthorizationCountry/AuthorizationCountryListPage/AuthorizationCountryListPage'
    ),
);

const BlockedPage = React.lazy(
  () => import('../../pages/Public/BlockedPage/BlockedPage'),
);

const PlayersListPage = React.lazy(
  () => import('../../pages/Admin/User/PlayersListPage/PlayersListPage'),
);

const PlayerEditPage = React.lazy(
  () => import('../../pages/Admin/User/PlayerEditPage/PlayerEditPage'),
);

const GameListPage = React.lazy(
  () => import('../../pages/Admin/Games/GamesListPage/GamesListPage'),
);

const GameCreatePage = React.lazy(
  () => import('../../pages/Admin/Games/GameCreatePage/GameCreatePage'),
);

const GameEditPage = React.lazy(
  () => import('../../pages/Admin/Games/GameEditPage/GameEditPage'),
);

const VipPage = React.lazy(() => import('../../pages/Public/VipPage/VipPage'));

const CashoutPage = React.lazy(
  () => import('../../pages/Public/CashoutPage/CashoutPage'),
);

const RegistrationConfirmationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const RulesPage = React.lazy(
  () => import('../../pages/Public/RulesPage/RulesPage'),
);

export type Props = {
  language: Language | null;
  onLanguagesInit: () => void;
  onLanguageFetch: (locale: string) => void;
  jwtToken: string | null;
  onRefreshToken: () => void;
  onSelectLocale: (locale: Locale) => void;
  onLogout: () => void;
  onTryAutoSignup: () => void;
  lastActionAt: moment.Moment | null;
  onFetchCurrentUser: () => void;
  isAuthenticated: boolean;
  currentUser: User | null;
  isCountryAuthorized: boolean;
  isInitCompleted: boolean;
  isCurrentUserLoading: boolean;
  isCountryAuthorizedLoading: boolean;
  onAuthorizeCountry: () => void;
  gameRoomCreateSuccess: boolean;
};

export const Router = ({
  language,
  onLanguagesInit,
  onLanguageFetch,
  jwtToken,
  onRefreshToken,
  onLogout,
  onTryAutoSignup,
  lastActionAt,
  onFetchCurrentUser,
  isAuthenticated,
  onSelectLocale,
  currentUser,
  isCountryAuthorized,
  isCurrentUserLoading,
  isInitCompleted,
  onAuthorizeCountry,
  isCountryAuthorizedLoading,
  gameRoomCreateSuccess,
}: Props) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  // const { disconnect } = useDisconnect(); uncomment if wallet login needed

  useEffect(() => {
    onAuthorizeCountry();
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (difference.asMinutes() < 5 && differenceLastAction.asMinutes() < 5) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
      // disconnect(); uncomment if wallet login needed
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated, gameRoomCreateSuccess]);

  useEffect(() => {
    if (isInitialLoading && currentUser) {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(DEFAULT_LOCALE as Locale);
  }, []);

  useEffect(() => {
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
  }, []);

  const getRoutes = () => {
    if (!isCountryAuthorized) {
      return (
        <>
          <Route path={routes.homepage} element={<BlockedPage />} />
          <Route path="*" element={<Navigate to={routes.homepage} />} />
        </>
      );
    }

    if (!isAuthenticated) {
      return (
        <>
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.admin} element={<SignUpPage />} />
          <Route path={routes.publicGames} element={<PublicGameListPage />} />
          <Route path={routes.policy} element={<PolicyPage />} />
          <Route path={routes.emailConfirmation} element={<HomePage />} />
          <Route path={routes.refCode} element={<HomePage />} />
          <Route
            path={routes.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
          <Route path={routes.rules} element={<RulesPage />} />
          <Route path="*" element={<Navigate to={routes.homepage} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser && currentUser.role === Roles.USER) {
      return (
        <>
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.publicGames} element={<PublicGameListPage />} />
          <Route path={routes.policy} element={<PolicyPage />} />
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={routes.singleGame} element={<SingleGame />} />
          <Route path={routes.emailConfirmation} element={<HomePage />} />
          <Route path={routes.vip} element={<VipPage />} />
          <Route path={routes.cashout} element={<CashoutPage />} />
          <Route path={routes.rules} element={<RulesPage />} />
          <Route path="*" element={<Navigate to={routes.profile} />} />
        </>
      );
    }
    if (isAuthenticated && currentUser && currentUser.role === Roles.ADMIN) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.users.list} />}
          />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.players.list} element={<PlayersListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.cashouts.list} element={<CashoutListPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.publicGames} element={<PublicGameListPage />} />
          <Route path={routes.games.list} element={<GameListPage />} />
          <Route path={routes.games.create} element={<GameCreatePage />} />
          <Route path={routes.games.edit} element={<GameEditPage />} />
          <Route path={routes.policy} element={<PolicyPage />} />
          <Route
            path={routes.authorizationCountries}
            element={<AuthorizationCountryListPage />}
          />
          <Route path={routes.players.list} element={<PlayersListPage />} />
          <Route path={routes.players.edit} element={<PlayerEditPage />} />
          <Route path={routes.rules} element={<RulesPage />} />

          <Route path="*" element={<Navigate to={routes.users.list} />} />
        </>
      );
    }
  };

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const isComponentReady = () => {
    if (isInitialLoading) {
      return (
        isInitCompleted &&
        !isCurrentUserLoading &&
        !isCountryAuthorizedLoading &&
        language
      );
    }

    return isInitCompleted && !isCountryAuthorizedLoading && language;
  };

  return (
    <BrowserRouter basename="/">
      {isComponentReady() ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout
                isAuthenticated={isAuthenticated}
                isValidated={isCountryAuthorized}
              >
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
          {/* <WalletConnect /> uncomment if wallet login needed*/}
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  isCountryAuthorized: state.authorizationCountry.countryAuthorizationSuccess,
  isCountryAuthorizedLoading:
    state.authorizationCountry.countryAuthorizationLoading,
  gameRoomCreateSuccess: state.gameRoom.createdGameRoomSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onAuthorizeCountry: () =>
    dispatch(authorizationCountryService.authorizeCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
