export const FETCH_CASHOUTS_LIST_START = 'FETCH_CASHOUTS_LIST_START';
export const FETCH_CASHOUTS_LIST_SUCCESS = 'FETCH_CASHOUTS_LIST_SUCCESS';
export const FETCH_CASHOUTS_LIST_FAIL = 'FETCH_CASHOUTS_LIST_FAIL';

export const CHANGE_CASHOUT_STATUS_START = 'CHANGE_CASHOUT_STATUS_START';
export const CHANGE_CASHOUT_STATUS_SUCCESS = 'CHANGE_CASHOUT_STATUS_SUCCESS';
export const CHANGE_CASHOUT_STATUS_FAIL = 'CHANGE_CASHOUT_STATUS_FAIL';

export const CREATE_CASHOUT_REQUEST_START = 'CREATE_CASHOUT_REQUEST_START';
export const CREATE_CASHOUT_REQUEST_SUCCESS = 'CREATE_CASHOUT_REQUEST_SUCCESS';
export const CREATE_CASHOUT_REQUEST_FAIL = 'CREATE_CASHOUT_REQUEST_FAIL';
export const RESET_CREATED_CASHOUT_REQUEST = 'RESET_CREATED_CASHOUT_REQUEST';

export const FETCH_PLAYER_CASHOUT_LIST_START =
  'FETCH_PLAYER_CASHOUT_LIST_START';
export const FETCH_PLAYER_CASHOUT_LIST_SUCCESS =
  'FETCH_PLAYER_CASHOUT_LIST_SUCCESS';
export const FETCH_PLAYER_CASHOUT_LIST_FAIL = 'FETCH_PLAYER_CASHOUT_LIST_FAIL';
export const RESET_PLAYER_CASHOUT_LIST_STORE =
  'RESET_PLAYER_CASHOUT_LIST_STORE';

export const RESET_CASHOUT_STORE = 'RESET_CASHOUT_STORE';
export const LOGOUT = 'LOGOUT';

export type CashoutActionTypes =
  | typeof FETCH_CASHOUTS_LIST_START
  | typeof FETCH_CASHOUTS_LIST_SUCCESS
  | typeof FETCH_CASHOUTS_LIST_FAIL
  | typeof CHANGE_CASHOUT_STATUS_START
  | typeof CHANGE_CASHOUT_STATUS_SUCCESS
  | typeof CHANGE_CASHOUT_STATUS_FAIL
  | typeof CREATE_CASHOUT_REQUEST_START
  | typeof CREATE_CASHOUT_REQUEST_SUCCESS
  | typeof CREATE_CASHOUT_REQUEST_FAIL
  | typeof RESET_CREATED_CASHOUT_REQUEST
  | typeof FETCH_PLAYER_CASHOUT_LIST_START
  | typeof FETCH_PLAYER_CASHOUT_LIST_SUCCESS
  | typeof FETCH_PLAYER_CASHOUT_LIST_FAIL
  | typeof RESET_PLAYER_CASHOUT_LIST_STORE
  | typeof RESET_CASHOUT_STORE
  | typeof LOGOUT;
