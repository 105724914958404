import React, { useEffect, useState } from 'react';
import styles from './ManualDepositModal.module.scss';
import Modal from '../../../common/Modal/Modal';
import { translate } from '../../../utility/messageTranslator/translate';
import { IntlShape, useIntl } from 'react-intl';
import ethIcon from '../../../assets/icons/eth.svg';
import CryptoCard from './CryptoCard/CryptoCard';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../../domain/User';
import { useForm } from '../../../hooks/useForm/useForm';
import { getGlobalError } from '../../../utility/error/httpErrorParser';
import { resetUserInfoStore } from '../../../store/user/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { UserUpdateInfoRequest } from '../../../store/user/service';
import * as userService from '../../../store/user/service';
import { HttpError } from '../../../config/Axios/axios-instance';
import TextField from '../../../common/TextField/TextField';
import Alert from '../../../common/Alert/Alert';
import solonaIcon from '../../../assets/icons/solona.svg';
import tronIcon from '../../../assets/icons/tron.svg';
import rippleIcon from '../../../assets/icons/ripple.svg';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: User | null;
  error: HttpError;
  onUpdateUserInfo: (inputs: UserUpdateInfoRequest, intl: IntlShape) => void;
  onResetUserInfoStore: () => void;
};

type FormInputs = {
  address: string;
};

const ManualDepositModal = ({
  isOpen,
  onClose,
  user,
  onUpdateUserInfo,
  onResetUserInfoStore,
  error,
}: Props) => {
  const intl = useIntl();
  const [clipboardText, setClipboardText] = useState<string | undefined>(
    undefined,
  );

  if (!user) {
    return <></>;
  }

  const handleClose = () => {
    onClose();
  };

  const CRYPTO_INFO = [
    {
      label: translate(intl, 'MANUAL_DEPOSIT.LABEL_SOL'),
      address: translate(intl, 'MANUAL_DEPOSIT.ADDRESS_SOL'),
      icon: solonaIcon,
    },
    {
      label: translate(intl, 'MANUAL_DEPOSIT.LABEL_TRX'),
      address: translate(intl, 'MANUAL_DEPOSIT.ADDRESS_TRX'),
      icon: tronIcon,
    },
    {
      label: translate(intl, 'MANUAL_DEPOSIT.LABEL_ETH'),
      address: translate(intl, 'MANUAL_DEPOSIT.ADDRESS_ETH'),
      icon: ethIcon,
    },
    {
      label: translate(intl, 'MANUAL_DEPOSIT.LABEL_XRP'),
      address: translate(intl, 'MANUAL_DEPOSIT.ADDRESS_XRP'),
      icon: rippleIcon,
    },
  ];

  const INPUTS = [
    {
      name: 'address',
      type: 'text',
      label: translate(intl, 'MANUAL_DEPOSIT.CRYPTO_LABEL'),
      value: user.address,
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 26,
        },
        {
          type: 'maxLength',
          parameter: 62,
        },
      ],
    },
  ];

  useEffect(() => {
    return () => onResetUserInfoStore();
  }, []);

  const handleSubmit = async (submitInputs: FormInputs) => {
    if (submitInputs.address === user.address) {
      return;
    }

    onUpdateUserInfo(
      {
        address: submitInputs.address,
      },
      intl,
    );
  };

  const { inputs, onSubmit, onInputChange, onSetValidationErrors } =
    useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={translate(intl, 'MANUAL_DEPOSIT.MODAL_TITLE')}
      className={styles.modalContainer}
    >
      <div className={styles.modalContent}>
        <div className={styles.subtitle}>
          {translate(intl, 'MANUAL_DEPOSIT.MODAL_SUBTITLE')}
        </div>
        {globalError && (
          <Alert
            variant="danger"
            capitalize={false}
            className={styles.alertContainer}
          >
            {translate(intl, globalError)}
          </Alert>
        )}
        {inputs.map((input) => (
          <TextField
            key={input.name}
            onChange={onInputChange}
            value={input.value?.toString() ?? ''}
            label={input.label ?? ''}
            inputLabelProps={{
              shrink: true,
            }}
            errors={input.validationErrors ?? []}
            onBlur={onSubmit}
            name={input.name}
            type={input.type}
            className={styles.input}
          />
        ))}
        <div className={styles.info}>
          {translate(intl, 'MANUAL_DEPOSIT.CRYPTO_INFO')}
        </div>
        <div className={styles.rate}>
          {translate(intl, 'MANUAL_DEPOSIT.CRYPTO_RATE')}
        </div>
        {CRYPTO_INFO.map((crypto) => (
          <CryptoCard
            key={crypto.address}
            label={crypto.label}
            address={crypto.address}
            icon={crypto.icon}
            setClipboardText={setClipboardText}
            clipboardText={clipboardText}
          />
        ))}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.user.currentUser,
  error: state.user.userUpdateInfoError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onUpdateUserInfo: (inputs: UserUpdateInfoRequest, intl: IntlShape) =>
    dispatch(userService.updateUserInfo(inputs, intl)),
  onResetUserInfoStore: () => dispatch(resetUserInfoStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualDepositModal);
