import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';
import moment from 'moment';
import { HttpError } from '../../config/Axios/axios-instance';
import { Locale } from '../../domain/Translation';
import { DEFAULT_LOCALE } from '../../config/constants';
import { User } from '../../domain/User';

export type AuthStateType = {
  jwtToken: string | null;
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: HttpError;
  loginLoading: boolean;
  loginError: HttpError;
  lastStoreActionAt: moment.Moment | null;
  selectedLocale: Locale;
  refreshTokenLoading: boolean;
  refreshTokenError: HttpError;
  registrationLoading: boolean;
  loginWalletConnectLoading: boolean;
  loginWalletConnectError: HttpError;
  emailConfirmLoading: boolean;
  emailConfirmError: HttpError;
  emailConfirmSuccess: boolean;
  refCode: string | undefined;
  registrationError: HttpError;
  registrationSuccess: boolean;
  registerConfirmLoading: boolean;
  registerConfirmError: HttpError;
  resetPasswordLoading: boolean;
  resetPasswordSuccess: boolean;
  resetPasswordError: HttpError;
  validateCodeLoading: boolean;
  validateCodeSuccess: boolean;
  validateCodeError: HttpError;
  validatedUser: User | null;
  remindLoading: boolean;
  remindError: HttpError;
  isRemindSuccess: boolean;
  loginVerificationLoading: boolean;
  loginVerificationSuccess: boolean;
  loginVerificationError: HttpError;
  loginVerificationEmail: string | null;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  loginLoading: false,
  loginError: null,
  lastStoreActionAt: null,
  refreshTokenLoading: false,
  refreshTokenError: null,
  registrationLoading: false,
  loginWalletConnectError: null,
  loginWalletConnectLoading: false,
  jwtToken: localStorage.getItem('token'),
  selectedLocale:
    (localStorage.getItem('selectedLocale') as Locale) ?? DEFAULT_LOCALE,
  emailConfirmLoading: false,
  emailConfirmError: null,
  emailConfirmSuccess: false,
  refCode: undefined,
  registrationError: null,
  registrationSuccess: false,
  registerConfirmLoading: false,
  registerConfirmError: null,
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeSuccess: false,
  validateCodeError: null,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  validatedUser: null,
  loginVerificationLoading: false,
  loginVerificationSuccess: false,
  loginVerificationError: null,
  loginVerificationEmail: null,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  jwtToken: action.jwtToken,
});

const loginGoogleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
});

const loginWalletConnectStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginWalletConnectLoading: true,
  loginWalletConnectError: null,
});

const loginWalletConnectSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginWalletConnectError: null,
  loginWalletConnectLoading: false,
  jwtToken: action.jwtToken,
});

const loginWalletConnectFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginWalletConnectError: action.loginWalletConnectError,
  loginWalletConnectLoading: false,
});

const loginStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginLoading: true,
});

const loginSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginError: null,
  loginLoading: false,
  jwtToken: action.jwtToken,
});

const loginFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginError: action.loginError,
  loginLoading: false,
  loginGoogleError: null,
});

const loginVerificationStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginVerificationLoading: true,
});

const loginVerificationSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginVerificationSuccess: true,
  loginVerificationError: null,
  loginVerificationLoading: false,
  loginVerificationEmail: action.loginVerificationEmail,
});

const loginVerificationFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginVerificationSuccess: false,
  loginVerificationError: action.loginVerificationError,
  loginVerificationLoading: false,
});

const logout = (state: AuthStateType): AuthStateType => ({
  ...state,
  jwtToken: null,
  isAuthenticated: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  lastStoreActionAt: null,
  isInitCompleted: true,
});

const selectLocale = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('selectedLocale', action.selectedLocale);

  return {
    ...state,
    selectedLocale: action.selectedLocale,
  };
};

const refreshTokenStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  refreshTokenLoading: true,
});

const refreshTokenSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenLoading: false,
  refreshTokenError: null,
  isInitCompleted: true,
  isAuthenticated: true,
  jwtToken: action.jwtToken,
});

const refreshTokenFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenError: action.refreshTokenError,
  refreshTokenLoading: false,
});

const updateLastAction = (state: AuthStateType): AuthStateType => ({
  ...state,
  lastStoreActionAt: moment(),
});

const emailConfirmStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  emailConfirmLoading: true,
});

const emailConfirmSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  emailConfirmError: null,
  emailConfirmLoading: false,
  emailConfirmSuccess: true,
});

const emailConfirmFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  emailConfirmError: action.emailConfirmError,
  emailConfirmLoading: false,
});

const setRefCode = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refCode: action.refCode,
});

const registrationStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registrationLoading: true,
});

const registrationSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  registrationError: null,
  registrationLoading: false,
  registrationSuccess: true,
});

const registrationFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registrationError: action.registrationError,
  registrationLoading: false,
});

const resetRegistration = (state: AuthStateType): AuthStateType => ({
  ...state,
  registrationError: null,
  registrationLoading: false,
  registrationSuccess: false,
});

const registerConfirmStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerConfirmLoading: true,
});

const registerConfirmSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  registerConfirmError: null,
  registerConfirmLoading: false,
  jwtToken: action.jwtToken,
});

const registerConfirmFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerConfirmError: action.registerConfirmError,
  registerConfirmLoading: false,
  isInitCompleted: true,
});

const remindStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindLoading: true,
  validatedUser: null,
  validateCodeError: null,
});

const remindSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindError: null,
  remindLoading: false,
  isRemindSuccess: true,
});

const remindFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  remindError: action.remindError,
  remindLoading: false,
});

const validateCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateCodeLoading: true,
});

const validateCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: null,
  validateCodeLoading: false,
  validateCodeSuccess: true,
  validatedUser: action.validatedUser,
});

const validateCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: action.validateCodeError,
  validateCodeLoading: false,
});

const resetPasswordStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  resetPasswordLoading: true,
});

const resetPasswordSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  resetPasswordError: null,
  resetPasswordLoading: false,
  resetPasswordSuccess: true,
  jwtToken: action.jwtToken,
});

const resetPasswordFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  resetPasswordError: action.resetPasswordError,
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  isInitCompleted: true,
});

const resetRemindPasswordStore = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
});

const resetLogin = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginError: null,
  loginLoading: false,
});

const resetLoginVerification = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginVerificationEmail: null,
  loginVerificationLoading: false,
  loginVerificationError: null,
  loginVerificationSuccess: false,
});

const resetGoogleAuthStore = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: false,
  loginGoogleError: null,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return loginStart(state);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.LOGIN_VERIFICATION_START:
      return loginVerificationStart(state);
    case actionTypes.LOGIN_VERIFICATION_SUCCESS:
      return loginVerificationSuccess(state, action);
    case actionTypes.LOGIN_VERIFICATION_FAIL:
      return loginVerificationFail(state, action);
    case actionTypes.LOGIN_GOOGLE_START:
      return loginGoogleStart(state);
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return loginGoogleSuccess(state, action);
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return loginGoogleFail(state, action);
    case actionTypes.WALLET_CONNECT_LOGIN_START:
      return loginWalletConnectStart(state);
    case actionTypes.WALLET_CONNECT_LOGIN_SUCCESS:
      return loginWalletConnectSuccess(state, action);
    case actionTypes.WALLET_CONNECT_LOGIN_FAIL:
      return loginWalletConnectFail(state, action);
    case actionTypes.SELECT_LOCALE:
      return selectLocale(state, action);
    case actionTypes.REFRESH_TOKEN_START:
      return refreshTokenStart(state);
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return refreshTokenSuccess(state, action);
    case actionTypes.REFRESH_TOKEN_FAIL:
      return refreshTokenFail(state, action);
    case actionTypes.EMAIL_CONFIRM_START:
      return emailConfirmStart(state);
    case actionTypes.EMAIL_CONFIRM_SUCCESS:
      return emailConfirmSuccess(state);
    case actionTypes.EMAIL_CONFIRM_FAIL:
      return emailConfirmFail(state, action);
    case actionTypes.SET_REF_CODE:
      return setRefCode(state, action);
    case actionTypes.REGISTRATION_START:
      return registrationStart(state);
    case actionTypes.REGISTRATION_SUCCESS:
      return registrationSuccess(state);
    case actionTypes.REGISTRATION_FAIL:
      return registrationFail(state, action);
    case actionTypes.RESET_REGISTRATION:
      return resetRegistration(state);
    case actionTypes.REGISTER_CONFIRM_START:
      return registerConfirmStart(state);
    case actionTypes.REGISTER_CONFIRM_SUCCESS:
      return registerConfirmSuccess(state, action);
    case actionTypes.REGISTER_CONFIRM_FAIL:
      return registerConfirmFail(state, action);
    case actionTypes.REMIND_START:
      return remindStart(state);
    case actionTypes.REMIND_SUCCESS:
      return remindSuccess(state);
    case actionTypes.REMIND_FAIL:
      return remindFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.RESET_REMIND_PASSWORD:
      return resetRemindPasswordStore(state);
    case actionTypes.VALIDATE_CODE_START:
      return validateCodeStart(state);
    case actionTypes.VALIDATE_CODE_SUCCESS:
      return validateCodeSuccess(state, action);
    case actionTypes.VALIDATE_CODE_FAIL:
      return validateCodeFail(state, action);
    case actionTypes.RESET_LOGIN:
      return resetLogin(state);
    case actionTypes.RESET_LOGIN_VERIFICATION:
      return resetLoginVerification(state);
    case actionTypes.RESET_GOOGLE_AUTH_STORE:
      return resetGoogleAuthStore(state);
    case actionTypes.LOGOUT:
      return logout(state);
    default:
      return updateLastAction(state);
  }
};

export default reducer;
