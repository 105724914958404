import * as actionTypes from './actionTypes';
import { AuthorizationCountry } from '../../domain/AuthorizationCountry';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';

export const fetchAuthorizationCountriesStart = () => ({
  type: actionTypes.FETCH_AUTHORIZATION_COUNTRIES_START,
});

export const fetchAuthorizationCountriesSuccess = (
  authorizationCountryList: ListResults<AuthorizationCountry>,
) => ({
  type: actionTypes.FETCH_AUTHORIZATION_COUNTRIES_SUCCESS,
  authorizationCountryList,
});

export const fetchAuthorizationCountriesFail = (
  authorizationCountryListError: HttpError,
) => ({
  type: actionTypes.FETCH_AUTHORIZATION_COUNTRIES_FAIL,
  authorizationCountryListError,
});

export const toggleAuthorizationCountryStart = () => ({
  type: actionTypes.TOGGLE_AUTHORIZATION_COUNTRY_START,
});

export const toggleAuthorizationCountrySuccess = (
  updatedAuthorizationCountry: AuthorizationCountry,
) => ({
  type: actionTypes.TOGGLE_AUTHORIZATION_COUNTRY_SUCCESS,
  updatedAuthorizationCountry,
});

export const toggleAuthorizationCountryFail = (
  updatedAuthorizationCountryError: HttpError,
) => ({
  type: actionTypes.TOGGLE_AUTHORIZATION_COUNTRY_FAIL,
  updatedAuthorizationCountryError,
});

export const resetAuthorizationCountryStore = () => ({
  type: actionTypes.RESET_AUTHORIZATION_COUNTRY_STORE,
});

export const authorizeCountryStart = () => ({
  type: actionTypes.AUTHORIZE_COUNTRY_START,
});

export const authorizeCountrySuccess = () => ({
  type: actionTypes.AUTHORIZE_COUNTRY_SUCCESS,
});

export const authorizeCountryFail = (countryAuthorizationError: HttpError) => ({
  type: actionTypes.AUTHORIZE_COUNTRY_FAIL,
  countryAuthorizationError,
});
