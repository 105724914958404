export const FETCH_GAMES_LIST_START = 'FETCH_GAMES_LIST_START';
export const FETCH_GAMES_LIST_SUCCESS = 'FETCH_GAMES_LIST_SUCCESS';
export const FETCH_GAMES_LIST_FAIL = 'FETCH_GAMES_LIST_FAIL';

export const FETCH_PUBLIC_GAMES_START = 'FETCH_PUBLIC_GAMES_START';
export const FETCH_PUBLIC_GAMES_SUCCESS = 'FETCH_PUBLIC_GAMES_SUCCESS';
export const FETCH_PUBLIC_GAMES_FAIL = 'FETCH_PUBLIC_GAMES_FAIL';

export const FETCH_PUBLIC_GAME_START = 'FETCH_PUBLIC_GAME_START';
export const FETCH_PUBLIC_GAME_SUCCESS = 'FETCH_PUBLIC_GAME_SUCCESS';
export const FETCH_PUBLIC_GAME_FAIL = 'FETCH_PUBLIC_GAME_FAIL';

export const FETCH_GAME_START = 'FETCH_GAME_START';
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS';
export const FETCH_GAME_FAIL = 'FETCH_GAME_FAIL';

export const CREATE_GAME_START = 'CREATE_GAME_START';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL';

export const UPDATE_GAME_START = 'UPDATE_GAME_START';
export const UPDATE_GAME_SUCCESS = 'UPDATE_GAME_SUCCESS';
export const UPDATE_GAME_FAIL = 'UPDATE_GAME_FAIL';

export const DELETE_GAME_START = 'DELETE_GAME_START';
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS';
export const DELETE_GAME_FAIL = 'DELETE_GAME_FAIL';

export const UPDATE_GAME_PRIORITY_START = 'UPDATE_GAME_PRIORITY_START';
export const UPDATE_GAME_PRIORITY_SUCCESS = 'UPDATE_GAME_PRIORITY_SUCCESS';
export const UPDATE_GAME_PRIORITY_FAIL = 'UPDATE_GAME_PRIORITY_FAIL';

export const RESET_CREATED_GAME_STORE = 'RESET_CREATED_GAME';
export const RESET_GAME_STORE = 'RESET_GAME_STORE';

export const LOGOUT = 'LOGOUT';

export type GameActionTypes =
  | typeof FETCH_GAMES_LIST_START
  | typeof FETCH_GAMES_LIST_SUCCESS
  | typeof FETCH_GAMES_LIST_FAIL
  | typeof FETCH_PUBLIC_GAMES_START
  | typeof FETCH_PUBLIC_GAMES_SUCCESS
  | typeof FETCH_PUBLIC_GAMES_FAIL
  | typeof FETCH_PUBLIC_GAME_START
  | typeof FETCH_PUBLIC_GAME_SUCCESS
  | typeof FETCH_PUBLIC_GAME_FAIL
  | typeof FETCH_GAME_START
  | typeof FETCH_GAME_SUCCESS
  | typeof FETCH_GAME_FAIL
  | typeof CREATE_GAME_START
  | typeof CREATE_GAME_SUCCESS
  | typeof CREATE_GAME_FAIL
  | typeof UPDATE_GAME_START
  | typeof UPDATE_GAME_SUCCESS
  | typeof UPDATE_GAME_FAIL
  | typeof DELETE_GAME_START
  | typeof DELETE_GAME_SUCCESS
  | typeof DELETE_GAME_FAIL
  | typeof UPDATE_GAME_PRIORITY_START
  | typeof UPDATE_GAME_PRIORITY_SUCCESS
  | typeof UPDATE_GAME_PRIORITY_FAIL
  | typeof RESET_CREATED_GAME_STORE
  | typeof RESET_GAME_STORE
  | typeof LOGOUT;
