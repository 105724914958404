export const FETCH_GAME_ROOM_START = 'FETCH_GAME_ROOM_START';
export const FETCH_GAME_ROOM_SUCCESS = 'FETCH_GAME_ROOM_SUCCESS';
export const FETCH_GAME_ROOM_FAIL = 'FETCH_GAME_ROOM_FAIL';

export const FETCH_PLAYER_SEQUENCE_START = 'FETCH_PLAYER_SEQUENCE_START';
export const FETCH_PLAYER_SEQUENCE_SUCCESS = 'FETCH_PLAYER_SEQUENCE_SUCCESS';
export const FETCH_PLAYER_SEQUENCE_FAIL = 'FETCH_PLAYER_SEQUENCE_FAIL';

export const FETCH_CREATED_GAME_ROOM_START = 'FETCH_CREATED_GAME_ROOM_START';
export const FETCH_CREATED_GAME_ROOM_SUCCESS =
  'FETCH_CREATED_GAME_ROOM_SUCCESS';
export const FETCH_CREATED_GAME_ROOM_FAIL = 'FETCH_CREATED_GAME_ROOM_FAIL';

export const UPDATE_GAME_ROOM_START = 'UPDATE_GAME_ROOM_START';
export const UPDATE_GAME_ROOM_SUCCESS = 'UPDATE_GAME_ROOM_SUCCESS';
export const UPDATE_GAME_ROOM_FAIL = 'UPDATE_GAME_ROOM_FAIL';

export const CREATE_JOKER_TREASURE_GAME_ROOM_START =
  'CREATE_JOKER_TREASURE_GAME_ROOM_START';
export const CREATE_JOKER_TREASURE_GAME_ROOM_SUCCESS =
  'CREATE_JOKER_TREASURE_GAME_ROOM_SUCCESS';
export const CREATE_JOKER_TREASURE_GAME_ROOM_FAIL =
  'CREATE_JOKER_TREASURE_GAME_ROOM_FAIL';

export const UPDATE_GAME_ROOM_ON_CARD_CLICK_START =
  'UPDATE_GAME_ROOM_ON_CARD_CLICK_START';
export const UPDATE_GAME_ROOM_ON_CARD_CLICK_SUCCESS =
  'UPDATE_GAME_ROOM_ON_CARD_CLICK_SUCCESS';
export const UPDATE_GAME_ROOM_ON_CARD_CLICK_FAIL =
  'UPDATE_GAME_ROOM_ON_CARD_CLICK_FAIL';

export const RESET_UPDATE_GAME_ROOM_ON_CARD_CLICK_STORE =
  'RESET_UPDATE_GAME_ROOM_ON_CARD_CLICK_STORE';
export const RESET_JOKER_TREASURE_GAME_ROOM_CREATE_STORE =
  'RESET_JOKER_TREASURE_GAME_ROOM_CREATE_STORE';
export const RESET_GAME_ROOM_CREATE_STORE = 'RESET_GAME_ROOM_CREATE_STORE';
export const RESET_GAME_ROOM_STORE = 'RESET_GAME_ROOM_STORE';

export type GameRoomActionTypes =
  | typeof FETCH_GAME_ROOM_START
  | typeof FETCH_GAME_ROOM_SUCCESS
  | typeof FETCH_GAME_ROOM_FAIL
  | typeof FETCH_PLAYER_SEQUENCE_START
  | typeof FETCH_PLAYER_SEQUENCE_SUCCESS
  | typeof FETCH_PLAYER_SEQUENCE_FAIL
  | typeof FETCH_CREATED_GAME_ROOM_START
  | typeof FETCH_CREATED_GAME_ROOM_SUCCESS
  | typeof FETCH_CREATED_GAME_ROOM_FAIL
  | typeof UPDATE_GAME_ROOM_START
  | typeof UPDATE_GAME_ROOM_SUCCESS
  | typeof UPDATE_GAME_ROOM_FAIL
  | typeof CREATE_JOKER_TREASURE_GAME_ROOM_START
  | typeof CREATE_JOKER_TREASURE_GAME_ROOM_SUCCESS
  | typeof CREATE_JOKER_TREASURE_GAME_ROOM_FAIL
  | typeof UPDATE_GAME_ROOM_ON_CARD_CLICK_START
  | typeof UPDATE_GAME_ROOM_ON_CARD_CLICK_SUCCESS
  | typeof UPDATE_GAME_ROOM_ON_CARD_CLICK_FAIL
  | typeof RESET_UPDATE_GAME_ROOM_ON_CARD_CLICK_STORE
  | typeof RESET_JOKER_TREASURE_GAME_ROOM_CREATE_STORE
  | typeof RESET_GAME_ROOM_CREATE_STORE
  | typeof RESET_GAME_ROOM_STORE;
