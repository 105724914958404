import * as actionTypes from './actionTypes';
import { PlayingHistoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { PlayingHistory } from '../../domain/PlayingHistory';
import { ListResults } from '../../common/List/List';

export type PlayingHistoryStateType = {
  playingHistoryError: HttpError;
  playingHistory: PlayingHistory[] | null;
  playingHistoryLoading: boolean;
  playingHistoryUpdateNeeded: boolean;
  playerPlayingHistoryError: HttpError;
  playerPlayingHistoryList: ListResults<PlayingHistory> | null;
  playerPlayingHistoryLoading: boolean;
};

export type PlayingHistoryActionType = PlayingHistoryStateType & {
  type: PlayingHistoryActionTypes;
};

export const initialState: PlayingHistoryStateType = {
  playingHistoryError: null,
  playingHistory: null,
  playingHistoryLoading: true,
  playingHistoryUpdateNeeded: false,
  playerPlayingHistoryError: null,
  playerPlayingHistoryList: null,
  playerPlayingHistoryLoading: false,
};

const fetchPlayingHistoryStart = (
  state: PlayingHistoryStateType,
): PlayingHistoryStateType => ({
  ...state,
  playingHistoryLoading: true,
});

const fetchPlayingHistorySuccess = (
  state: PlayingHistoryStateType,
  action: PlayingHistoryActionType,
): PlayingHistoryStateType => ({
  ...state,
  playingHistory: action.playingHistory,
  playingHistoryLoading: false,
  playingHistoryError: null,
  playingHistoryUpdateNeeded: false,
});

const fetchPlayingHistoryFail = (
  state: PlayingHistoryStateType,
  action: PlayingHistoryActionType,
): PlayingHistoryStateType => ({
  ...state,
  playingHistoryError: action.playingHistoryError,
  playingHistoryLoading: false,
});

const fetchPlayerPlayingHistoryStart = (
  state: PlayingHistoryStateType,
): PlayingHistoryStateType => ({
  ...state,
  playerPlayingHistoryLoading: true,
});

const fetchPlayerPlayingHistorySuccess = (
  state: PlayingHistoryStateType,
  action: PlayingHistoryActionType,
): PlayingHistoryStateType => ({
  ...state,
  playerPlayingHistoryList: action.playerPlayingHistoryList,
  playerPlayingHistoryLoading: false,
  playerPlayingHistoryError: null,
});

const fetchPlayerPlayingHistoryFail = (
  state: PlayingHistoryStateType,
  action: PlayingHistoryActionType,
): PlayingHistoryStateType => ({
  ...state,
  playerPlayingHistoryError: action.playerPlayingHistoryError,
  playerPlayingHistoryLoading: false,
});

const resetPlayingHistoryStore = (
  state: PlayingHistoryStateType,
): PlayingHistoryStateType => ({
  ...initialState,
});

const completelyResetPlayingHistoryStore = (): PlayingHistoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PlayingHistoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PLAYING_HISTORY_START:
      return fetchPlayingHistoryStart(state);
    case actionTypes.FETCH_PLAYING_HISTORY_SUCCESS:
      return fetchPlayingHistorySuccess(state, action);
    case actionTypes.FETCH_PLAYING_HISTORY_FAIL:
      return fetchPlayingHistoryFail(state, action);
    case actionTypes.RESET_PLAYING_HISTORY_STORE:
      return resetPlayingHistoryStore(state);
    case actionTypes.FETCH_PLAYER_PLAYING_HISTORY_START:
      return fetchPlayerPlayingHistoryStart(state);
    case actionTypes.FETCH_PLAYER_PLAYING_HISTORY_SUCCESS:
      return fetchPlayerPlayingHistorySuccess(state, action);
    case actionTypes.FETCH_PLAYER_PLAYING_HISTORY_FAIL:
      return fetchPlayerPlayingHistoryFail(state, action);
    case actionTypes.LOGOUT:
      return completelyResetPlayingHistoryStore();
    default:
      return state;
  }
};

export default reducer;
