import * as actionTypes from './actionTypes';
import { CashoutActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Cashout } from '../../domain/Cashout';
import { ListResults } from '../../common/List/List';

export type CashoutStateType = {
  cashoutsList: ListResults<Cashout> | null;
  cashoutsListLoading: boolean;
  cashoutsListError: HttpError;
  cashoutsListUpdateNeeded: boolean;
  cashoutStatusChangeLoading: boolean;
  cashoutStatusChangeError: HttpError | null;
  cashoutStatusChangeSuccess: boolean;
  cashoutCreateRequestLoading: boolean;
  cashoutCreateRequestError: HttpError;
  cashoutCreateRequestSuccess: boolean;
  createdCashoutRequest: Cashout | null;
  playerCashoutList: ListResults<Cashout> | null;
  playerCashoutListLoading: boolean;
  playerCashoutListError: HttpError;
  playerCashoutListUpdateNeeded: boolean;
};

export type CashoutActionType = CashoutStateType & {
  type: CashoutActionTypes;
};

export const initialState: CashoutStateType = {
  cashoutsList: null,
  cashoutsListLoading: true,
  cashoutsListError: null,
  cashoutsListUpdateNeeded: false,
  cashoutStatusChangeLoading: false,
  cashoutStatusChangeError: null,
  cashoutStatusChangeSuccess: false,
  cashoutCreateRequestLoading: false,
  cashoutCreateRequestError: null,
  cashoutCreateRequestSuccess: false,
  createdCashoutRequest: null,
  playerCashoutList: null,
  playerCashoutListLoading: true,
  playerCashoutListError: null,
  playerCashoutListUpdateNeeded: false,
};

const fetchCashoutsListStart = (state: CashoutStateType): CashoutStateType => ({
  ...state,
  cashoutsListLoading: true,
});

const fetchCashoutsListSuccess = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  cashoutsListLoading: false,
  cashoutsList: action.cashoutsList,
  cashoutsListUpdateNeeded: false,
});

const fetchCashoutsListFail = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  cashoutsListLoading: false,
  cashoutsListError: action.cashoutsListError,
});

const changeCashoutStatusStart = (
  state: CashoutStateType,
): CashoutStateType => ({
  ...state,
  cashoutStatusChangeLoading: true,
});

const changeCashoutStatusSuccess = (
  state: CashoutStateType,
): CashoutStateType => ({
  ...state,
  cashoutStatusChangeLoading: false,
  cashoutStatusChangeError: null,
  cashoutStatusChangeSuccess: true,
  cashoutsListUpdateNeeded: true,
  playerCashoutListUpdateNeeded: true,
});

const changeCashoutStatusFail = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  cashoutStatusChangeLoading: false,
  cashoutStatusChangeError: action.cashoutStatusChangeError,
});

const createCashoutRequestStart = (
  state: CashoutStateType,
): CashoutStateType => ({
  ...state,
  cashoutCreateRequestLoading: true,
});

const createCashoutRequestSuccess = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  cashoutCreateRequestLoading: false,
  cashoutCreateRequestError: null,
  cashoutCreateRequestSuccess: true,
  createdCashoutRequest: action.createdCashoutRequest,
});

const createCashoutRequestFail = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  cashoutCreateRequestLoading: false,
  cashoutCreateRequestError: action.cashoutCreateRequestError,
});

const resetCreatedCashoutRequest = (
  state: CashoutStateType,
): CashoutStateType => ({
  ...state,
  cashoutCreateRequestLoading: false,
  cashoutCreateRequestError: null,
  cashoutCreateRequestSuccess: false,
  createdCashoutRequest: null,
});

const fetchPlayerCashoutListStart = (
  state: CashoutStateType,
): CashoutStateType => ({
  ...state,
  playerCashoutListLoading: true,
});

const fetchPlayerCashoutListSuccess = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  playerCashoutListLoading: false,
  playerCashoutList: action.playerCashoutList,
  playerCashoutListUpdateNeeded: false,
});

const fetchPlayerCashoutListFail = (
  state: CashoutStateType,
  action: CashoutActionType,
): CashoutStateType => ({
  ...state,
  playerCashoutListLoading: false,
  playerCashoutListError: action.playerCashoutListError,
});

const resetPlayerCashoutListStore = (
  state: CashoutStateType,
): CashoutStateType => ({
  ...state,
  playerCashoutList: null,
  playerCashoutListLoading: false,
  playerCashoutListError: null,
  playerCashoutListUpdateNeeded: false,
});

const resetCashoutStore = (): CashoutStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CashoutActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CASHOUTS_LIST_START:
      return fetchCashoutsListStart(state);
    case actionTypes.FETCH_CASHOUTS_LIST_SUCCESS:
      return fetchCashoutsListSuccess(state, action);
    case actionTypes.FETCH_CASHOUTS_LIST_FAIL:
      return fetchCashoutsListFail(state, action);
    case actionTypes.FETCH_PLAYER_CASHOUT_LIST_START:
      return fetchPlayerCashoutListStart(state);
    case actionTypes.FETCH_PLAYER_CASHOUT_LIST_SUCCESS:
      return fetchPlayerCashoutListSuccess(state, action);
    case actionTypes.FETCH_PLAYER_CASHOUT_LIST_FAIL:
      return fetchPlayerCashoutListFail(state, action);
    case actionTypes.RESET_PLAYER_CASHOUT_LIST_STORE:
      return resetPlayerCashoutListStore(state);
    case actionTypes.CHANGE_CASHOUT_STATUS_START:
      return changeCashoutStatusStart(state);
    case actionTypes.CHANGE_CASHOUT_STATUS_SUCCESS:
      return changeCashoutStatusSuccess(state);
    case actionTypes.CHANGE_CASHOUT_STATUS_FAIL:
      return changeCashoutStatusFail(state, action);
    case actionTypes.CREATE_CASHOUT_REQUEST_START:
      return createCashoutRequestStart(state);
    case actionTypes.CREATE_CASHOUT_REQUEST_SUCCESS:
      return createCashoutRequestSuccess(state, action);
    case actionTypes.CREATE_CASHOUT_REQUEST_FAIL:
      return createCashoutRequestFail(state, action);
    case actionTypes.RESET_CREATED_CASHOUT_REQUEST:
      return resetCreatedCashoutRequest(state);
    case actionTypes.RESET_CASHOUT_STORE:
      return resetCashoutStore();
    case actionTypes.LOGOUT:
      return resetCashoutStore();
    default:
      return state;
  }
};

export default reducer;
