import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import instance from '../Axios/axios-instance';
import queueReducer, { QueueStateType } from '../../store/queue/reducer';
import gameRoomReducer, {
  GameRoomStateType,
} from '../../store/game-room/reducer';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import authorizationCountryReducer, {
  AuthorizationCountryStateType,
} from '../../store/authorizationCountry/reducer';
import cashoutReducer, { CashoutStateType } from '../../store/cashout/reducer';
import gameReducer, { GameStateType } from '../../store/game/reducer';
import categoryReducer, {
  CategoryStateType,
} from '../../store/category/reducer';
import playingHistoryReducer, {
  PlayingHistoryStateType,
} from '../../store/playing-history/reducer';
import balanceHistoryReducer, {
  BalanceHistoryStateType,
} from '../../store/balance-history/reducer';
import weekGameReducer, {
  WeekGameStateType,
} from '../../store/week-game/reducer';
import warzoneGameRoomReducer, {
  WarzoneGameRoomStateType,
} from '../../store/warzone-game-room/reducer';

export type StoreState = {
  language: LanguageStateType;
  queue: QueueStateType;
  gameRoom: GameRoomStateType;
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  authorizationCountry: AuthorizationCountryStateType;
  cashout: CashoutStateType;
  game: GameStateType;
  category: CategoryStateType;
  playingHistory: PlayingHistoryStateType;
  balanceHistory: BalanceHistoryStateType;
  weekGame: WeekGameStateType;
  warzoneGameRoom: WarzoneGameRoomStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  language: languageReducer,
  queue: queueReducer,
  gameRoom: gameRoomReducer,
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  authorizationCountry: authorizationCountryReducer,
  cashout: cashoutReducer,
  game: gameReducer,
  category: categoryReducer,
  playingHistory: playingHistoryReducer,
  balanceHistory: balanceHistoryReducer,
  weekGame: weekGameReducer,
  warzoneGameRoom: warzoneGameRoomReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
