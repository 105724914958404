import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { PlayersListParams } from './service';
import { DEFAULT_LIST_PARAMS } from 'src/hooks/useList/useList';

export type UserStateType = {
  user: User | null;
  userLoading: boolean;
  userError: HttpError;
  usersList: ListResults<User> | null;
  usersLoading: boolean;
  usersError: HttpError;
  playersList: ListResults<User> | null;
  playersLoading: boolean;
  playersError: HttpError;
  playersListUpdateNeeded: boolean;
  userCreateLoading: boolean;
  userCreateError: HttpError;
  userCreateSuccess: boolean;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  userDeleteLoading: boolean;
  userDeleteError: HttpError;
  userDeleteSuccess: boolean;
  usersListUpdateNeeded: boolean;
  currentUser: User | null;
  currentUserLoading: boolean;
  allUsersError: HttpError;
  allUsersList: ListResults<User> | null;
  allUsersLoading: boolean;
  userUpdateInfoLoading: boolean;
  userUpdateInfoError: HttpError;
  userUpdateInfoSuccess: boolean;
  userChangePasswordLoading: boolean;
  userChangePasswordError: HttpError;
  userChangePasswordSuccess: boolean;
  createdUser: User | null;
  balanceUpdateError: HttpError;
  balanceUpdateLoading: boolean;
  balanceUpdateSuccess: boolean;
  statusUpdateError: HttpError;
  statusUpdateLoading: boolean;
  statusUpdateSuccess: boolean;
  depositError: HttpError;
  depositLoading: boolean;
  depositSuccess: boolean;
  playerBalanceHistoryListUpdateNeeded: boolean;
  invitedUsers: User[] | null;
  invitedUsersLoading: boolean;
  invitedUsersError: HttpError;
  refOwner: string | null;
  refOwnerLoading: boolean;
  refOwnerError: HttpError;
  totalPlayersCoinsLoading: boolean;
  totalPlayersCoins: number | null;
  totalPlayersCoinsError: HttpError;
  playerListParams: PlayersListParams;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  user: null,
  userLoading: false,
  userError: null,
  userCreateError: null,
  userCreateLoading: false,
  userCreateSuccess: false,
  userDeleteError: null,
  userDeleteLoading: false,
  userDeleteSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  usersError: null,
  usersList: null,
  usersLoading: true,
  usersListUpdateNeeded: false,
  currentUser: null,
  currentUserLoading: false,
  allUsersError: null,
  allUsersList: null,
  allUsersLoading: true,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
  userChangePasswordError: null,
  userChangePasswordLoading: false,
  userChangePasswordSuccess: false,
  createdUser: null,
  playersError: null,
  playersListUpdateNeeded: false,
  playersList: null,
  playersLoading: false,
  balanceUpdateError: null,
  balanceUpdateLoading: false,
  statusUpdateError: null,
  statusUpdateLoading: false,
  statusUpdateSuccess: false,
  balanceUpdateSuccess: false,
  depositError: null,
  depositLoading: false,
  depositSuccess: false,
  playerBalanceHistoryListUpdateNeeded: false,
  invitedUsers: null,
  invitedUsersLoading: false,
  invitedUsersError: null,
  refOwner: null,
  refOwnerLoading: false,
  refOwnerError: null,
  totalPlayersCoinsLoading: false,
  totalPlayersCoins: null,
  totalPlayersCoinsError: null,
  playerListParams: DEFAULT_LIST_PARAMS,
};

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userLoading: true,
  balanceUpdateSuccess: false,
  statusUpdateSuccess: false,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
});

const fetchUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersList: action.usersList,
  usersLoading: false,
  usersError: null,
  usersListUpdateNeeded: false,
});

const fetchUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const fetchPlayersStart = (state: UserStateType): UserStateType => ({
  ...state,
  playersLoading: true,
  statusUpdateSuccess: false,
});

const fetchPlayersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  playersList: action.playersList,
  playersLoading: false,
  playersError: null,
  playersListUpdateNeeded: false,
});

const fetchPlayersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  playersError: action.playersError,
  playersLoading: false,
});

const fetchAllUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  allUsersLoading: true,
});

const fetchAllUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersList: action.allUsersList,
  allUsersLoading: false,
  allUsersError: null,
});

const fetchAllUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersError: action.allUsersError,
  allUsersLoading: false,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: null,
  userCreateSuccess: true,
  usersListUpdateNeeded: true,
  createdUser: action.createdUser,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: action.userCreateError,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
  usersListUpdateNeeded: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const updateUserStatusStart = (state: UserStateType): UserStateType => ({
  ...state,
  statusUpdateLoading: true,
  statusUpdateSuccess: false,
});

const updateUserStatusSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  statusUpdateSuccess: true,
  statusUpdateLoading: false,
  statusUpdateError: null,
  playersListUpdateNeeded: true,
});

const updateUserStatusFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  statusUpdateLoading: false,
  statusUpdateError: action.statusUpdateError,
});

const updateUserBalanceStart = (state: UserStateType): UserStateType => ({
  ...state,
  balanceUpdateLoading: true,
  balanceUpdateSuccess: false,
});

const updateUserBalanceSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  balanceUpdateSuccess: true,
  balanceUpdateLoading: false,
  balanceUpdateError: null,
  playersListUpdateNeeded: true,
  playerBalanceHistoryListUpdateNeeded: true,
});

const updateUserBalanceFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  balanceUpdateLoading: false,
  balanceUpdateError: action.balanceUpdateError,
});

const resetUpdatedUserBalanceStore = (state: UserStateType): UserStateType => ({
  ...initialState,
  currentUser: state.currentUser,
  balanceUpdateSuccess: false,
  balanceUpdateLoading: false,
  balanceUpdateError: null,
});

const deleteUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: true,
});

const deleteUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: null,
  userDeleteSuccess: true,
  usersListUpdateNeeded: true,
});

const deleteUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: action.userDeleteError,
});

const updateUserInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: true,
});

const updateUserInfoSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: null,
  userUpdateInfoSuccess: true,
});

const updateUserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: action.userUpdateInfoError,
});

const changeUserPasswordStart = (state: UserStateType): UserStateType => ({
  ...state,
  userChangePasswordLoading: true,
});

const changeUserPasswordSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userChangePasswordLoading: false,
  userChangePasswordError: null,
  userChangePasswordSuccess: true,
});

const changeUserPasswordFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userChangePasswordLoading: false,
  userChangePasswordError: action.userChangePasswordError,
});

const depositStart = (state: UserStateType): UserStateType => ({
  ...state,
  depositLoading: true,
});

const depositSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  depositLoading: false,
  depositError: null,
  depositSuccess: true,
});

const depositFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  depositLoading: false,
  depositError: action.depositError,
});

const depositReset = (state: UserStateType): UserStateType => ({
  ...state,
  depositSuccess: false,
  depositError: null,
});

const fetchInvitedUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  invitedUsersLoading: true,
});

const fetchInvitedUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  invitedUsers: action.invitedUsers,
  invitedUsersLoading: false,
  invitedUsersError: null,
});

const fetchInvitedUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  invitedUsersError: action.invitedUsersError,
  invitedUsersLoading: false,
});

const fetchRefOwnerStart = (state: UserStateType): UserStateType => ({
  ...state,
  refOwnerLoading: true,
});

const fetchRefOwnerSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  refOwner: action.refOwner,
  refOwnerLoading: false,
  refOwnerError: null,
});

const fetchRefOwnerFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  refOwnerError: action.userError,
  refOwnerLoading: false,
});

const fetchTotalPlayersCoinsStart = (state: UserStateType): UserStateType => ({
  ...state,
  totalPlayersCoinsLoading: true,
});

const fetchTotalPlayersCoinsSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  totalPlayersCoins: action.totalPlayersCoins,
  totalPlayersCoinsLoading: false,
});

const fetchTotalPlayersCoinsFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  playersError: action.playersError,
  totalPlayersCoins: null,
});

const setPlayerListParams = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  playerListParams: action.playerListParams,
});

const resetChangeUserPasswordStore = (state: UserStateType): UserStateType => ({
  ...state,
  userChangePasswordError: null,
  userChangePasswordLoading: false,
  userChangePasswordSuccess: false,
});

const resetUserInfoStore = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
});

const resetUserStore = (state: UserStateType): UserStateType => ({
  ...initialState,
  playerListParams: state.playerListParams,
  currentUser: state.currentUser,
});

const completelyResetUserStore = (): UserStateType => ({
  ...initialState,
});

const resetCreateUserStore = (state: UserStateType): UserStateType => ({
  ...state,
  createdUser: null,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.FETCH_ALL_USERS_START:
      return fetchAllUsersStart(state);
    case actionTypes.FETCH_ALL_USERS_SUCCESS:
      return fetchAllUsersSuccess(state, action);
    case actionTypes.FETCH_ALL_USERS_FAIL:
      return fetchAllUsersFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.UPDATE_USER_INFO_START:
      return updateUserInfoStart(state);
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return updateUserInfoSuccess(state);
    case actionTypes.UPDATE_USER_INFO_FAIL:
      return updateUserInfoFail(state, action);
    case actionTypes.CHANGE_USER_PASSWORD_START:
      return changeUserPasswordStart(state);
    case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return changeUserPasswordSuccess(state);
    case actionTypes.CHANGE_USER_PASSWORD_FAIL:
      return changeUserPasswordFail(state, action);
    case actionTypes.FETCH_PLAYERS_START:
      return fetchPlayersStart(state);
    case actionTypes.FETCH_PLAYERS_SUCCESS:
      return fetchPlayersSuccess(state, action);
    case actionTypes.FETCH_PLAYERS_FAIL:
      return fetchPlayersFail(state, action);
    case actionTypes.SET_PLAYER_LIST_PARAMS:
      return setPlayerListParams(state, action);
    case actionTypes.UPDATE_BALANCE_START:
      return updateUserBalanceStart(state);
    case actionTypes.UPDATE_BALANCE_SUCCESS:
      return updateUserBalanceSuccess(state);
    case actionTypes.UPDATE_BALANCE_FAIL:
      return updateUserBalanceFail(state, action);
    case actionTypes.UPDATE_USER_STATUS_START:
      return updateUserStatusStart(state);
    case actionTypes.UPDATE_USER_STATUS_SUCCESS:
      return updateUserStatusSuccess(state);
    case actionTypes.UPDATE_USER_STATUS_FAIL:
      return updateUserStatusFail(state, action);
    case actionTypes.DEPOSIT_START:
      return depositStart(state);
    case actionTypes.DEPOSIT_SUCCESS:
      return depositSuccess(state);
    case actionTypes.DEPOSIT_FAIL:
      return depositFail(state, action);
    case actionTypes.DEPOSIT_RESET:
      return depositReset(state);
    case actionTypes.RESET_UPDATED_USER_BALANCE_STORE:
      return resetUpdatedUserBalanceStore(state);
    case actionTypes.FETCH_INVITED_USERS_START:
      return fetchInvitedUsersStart(state);
    case actionTypes.FETCH_INVITED_USERS_SUCCESS:
      return fetchInvitedUsersSuccess(state, action);
    case actionTypes.FETCH_INVITED_USERS_FAIL:
      return fetchInvitedUsersFail(state, action);
    case actionTypes.FETCH_REF_OWNER_START:
      return fetchRefOwnerStart(state);
    case actionTypes.FETCH_REF_OWNER_SUCCESS:
      return fetchRefOwnerSuccess(state, action);
    case actionTypes.FETCH_REF_OWNER_FAIL:
      return fetchRefOwnerFail(state, action);
    case actionTypes.FETCH_TOTAL_PLAYERS_COINS_START:
      return fetchTotalPlayersCoinsStart(state);
    case actionTypes.FETCH_TOTAL_PLAYERS_COINS_SUCCESS:
      return fetchTotalPlayersCoinsSuccess(state, action);
    case actionTypes.FETCH_TOTAL_PLAYERS_COINS_FAIL:
      return fetchTotalPlayersCoinsFail(state, action);
    case actionTypes.RESET_CHANGE_USER_PASSWORD_STORE:
      return resetChangeUserPasswordStore(state);
    case actionTypes.RESET_USER_INFO_STORE:
      return resetUserInfoStore(state);
    case actionTypes.RESET_USER_STORE:
      return resetUserStore(state);
    case actionTypes.RESET_CREATED_USER:
      return resetCreateUserStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserStore();
    default:
      return state;
  }
};

export default reducer;
