import React, { useMemo, useRef, useEffect } from 'react';
import styles from './CryptoCard.module.scss';
import { ReactSVG } from 'react-svg';
import copyIcon from '../../../../assets/icons/copy.svg';
import checkMark from '../../../../assets/icons/agree.svg';
import cx from 'classnames';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';

type Props = {
  label: string;
  icon: string;
  address: string;
  setClipboardText: (state: string | undefined) => void;
  clipboardText?: string;
};

const MOBILE_BREAK_POINT = 600;

const CryptoCard = ({
  label,
  icon,
  address,
  setClipboardText,
  clipboardText,
}: Props) => {
  const { width } = useWindowSize();
  const addressRef = useRef<HTMLDivElement>(null);

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(address);
    setClipboardText(address);
  };

  const isAddressCopied = useMemo(
    () => clipboardText === address,
    [clipboardText, address],
  );

  const getAddress = () => {
    if (!width || width >= MOBILE_BREAK_POINT) {
      return address;
    }

    return `${address.substring(0, 10)}...${address.slice(-10)}`;
  };

  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      const selection = window.getSelection();
      if (selection && selection.toString().includes('...')) {
        event.clipboardData?.setData('text/plain', address);
        event.preventDefault();
      }
    };

    const addressElement = addressRef.current;
    if (addressElement) {
      addressElement.addEventListener('copy', handleCopy);
    }

    return () => {
      if (addressElement) {
        addressElement.removeEventListener('copy', handleCopy);
      }
    };
  }, [address]);

  return (
    <div className={styles.cryptoCard}>
      <div className={styles.label}>{label}</div>
      <div className={styles.addressContainer}>
        <ReactSVG src={icon} />
        <div className={styles.address} ref={addressRef}>
          {getAddress()}
        </div>
        <div
          className={cx(styles.copyIcon, { [styles.copied]: isAddressCopied })}
          onClick={handleCopyButtonClick}
        >
          <ReactSVG src={isAddressCopied ? checkMark : copyIcon} />
        </div>
      </div>
    </div>
  );
};

export default CryptoCard;
