import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { PlayersListParams } from './service';

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (usersList: ListResults<User>) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  usersList,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const fetchPlayersStart = () => ({
  type: actionTypes.FETCH_PLAYERS_START,
});

export const fetchPlayersSuccess = (playersList: ListResults<User>) => ({
  type: actionTypes.FETCH_PLAYERS_SUCCESS,
  playersList,
});

export const fetchPlayersFail = (playersError: HttpError) => ({
  type: actionTypes.FETCH_PLAYERS_FAIL,
  playersError,
});

export const fetchAllUsersStart = () => ({
  type: actionTypes.FETCH_ALL_USERS_START,
});

export const fetchAllUsersSuccess = (allUsersList: ListResults<User>) => ({
  type: actionTypes.FETCH_ALL_USERS_SUCCESS,
  allUsersList,
});

export const fetchAllUsersFail = (allUsersError: HttpError) => ({
  type: actionTypes.FETCH_ALL_USERS_FAIL,
  allUsersError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = (createdUser: User) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  createdUser,
});

export const createUserFail = (userCreateError: HttpError) => ({
  type: actionTypes.CREATE_USER_FAIL,
  userCreateError,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const updateUserBalanceStart = () => ({
  type: actionTypes.UPDATE_BALANCE_START,
});

export const updateUserBalanceSuccess = () => ({
  type: actionTypes.UPDATE_BALANCE_SUCCESS,
});

export const updateUserBalanceFail = (balanceUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_BALANCE_FAIL,
  balanceUpdateError,
});

export const updateUserStatusStart = () => ({
  type: actionTypes.UPDATE_BALANCE_START,
});

export const updateUserStatusSuccess = () => ({
  type: actionTypes.UPDATE_BALANCE_SUCCESS,
});

export const updateUserStatusFail = (statusUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_BALANCE_FAIL,
  statusUpdateError,
});

export const depositStart = () => ({
  type: actionTypes.DEPOSIT_START,
});

export const depositSuccess = () => ({
  type: actionTypes.DEPOSIT_SUCCESS,
});

export const depositFail = (depositError: HttpError) => ({
  type: actionTypes.DEPOSIT_FAIL,
  depositError,
});

export const depositReset = () => ({
  type: actionTypes.DEPOSIT_RESET,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (cityUserError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  cityUserError,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const updateUserInfoStart = () => ({
  type: actionTypes.UPDATE_USER_INFO_START,
});

export const updateUserInfoSuccess = () => ({
  type: actionTypes.UPDATE_USER_INFO_SUCCESS,
});

export const updateUserInfoFail = (userUpdateInfoError: HttpError) => ({
  type: actionTypes.UPDATE_USER_INFO_FAIL,
  userUpdateInfoError,
});

export const changeUserPasswordStart = () => ({
  type: actionTypes.CHANGE_USER_PASSWORD_START,
});

export const changeUserPasswordSuccess = () => ({
  type: actionTypes.CHANGE_USER_PASSWORD_SUCCESS,
});

export const changeUserPasswordFail = (userChangePasswordError: HttpError) => ({
  type: actionTypes.CHANGE_USER_PASSWORD_FAIL,
  userChangePasswordError,
});

export const fetchInvitedUsersStart = () => ({
  type: actionTypes.FETCH_INVITED_USERS_START,
});

export const fetchInvitedUsersSuccess = (invitedUsers: User) => ({
  type: actionTypes.FETCH_INVITED_USERS_SUCCESS,
  invitedUsers,
});

export const fetchInvitedUsersFail = (invitedUsersError: HttpError) => ({
  type: actionTypes.FETCH_INVITED_USERS_FAIL,
  invitedUsersError,
});

export const fetchRefOwnerStart = () => ({
  type: actionTypes.FETCH_REF_OWNER_START,
});

export const fetchRefOwnerSuccess = (refOwner: string) => ({
  type: actionTypes.FETCH_REF_OWNER_SUCCESS,
  refOwner,
});

export const fetchRefOwnerFail = (refOwnerError: HttpError) => ({
  type: actionTypes.FETCH_REF_OWNER_FAIL,
  refOwnerError,
});

export const fetchTotalPlayersCoinsStart = () => ({
  type: actionTypes.FETCH_TOTAL_PLAYERS_COINS_START,
});

export const fetchTotalPlayersCoinsSuccess = (totalPlayersCoins: number) => ({
  type: actionTypes.FETCH_TOTAL_PLAYERS_COINS_SUCCESS,
  totalPlayersCoins,
});

export const fetchTotalPlayersCoinsFail = (
  totalPlayersCoinsError: HttpError,
) => ({
  type: actionTypes.FETCH_TOTAL_PLAYERS_COINS_FAIL,
  totalPlayersCoinsError,
});

export const setPlayerListParams = (playerListParams: PlayersListParams) => ({
  type: actionTypes.SET_PLAYER_LIST_PARAMS,
  playerListParams,
});

export const resetChangeUserPasswordStore = () => ({
  type: actionTypes.RESET_CHANGE_USER_PASSWORD_STORE,
});

export const resetUserInfoStore = () => ({
  type: actionTypes.RESET_USER_INFO_STORE,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});

export const resetCreatedUserStore = () => ({
  type: actionTypes.RESET_CREATED_USER,
});

export const resetUpdatedUserBalanceStore = () => ({
  type: actionTypes.RESET_UPDATED_USER_BALANCE_STORE,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});
