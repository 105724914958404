import React from 'react';
import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
// import { WagmiConfig } from 'wagmi'; uncomment if wallet login needed
import { sepolia, mainnet } from 'viem/chains';

const projectId = process.env.REACT_APP_PROJECT_ID || '';

const metadata = {
  name: 'Guby games',
  description: 'Guby games',
  url: process.env.REACT_APP_PUBLIC_URL || '',
  icons: [],
};

export const chain =
  process.env.REACT_APP_NETWORK === 'testnet' ? sepolia : mainnet;

const wagmiConfig = defaultWagmiConfig({
  chains: [chain],
  projectId,
  metadata,
});

createWeb3Modal({ wagmiConfig, projectId, chains: [chain] });

const App = () => {
  return (
    <GoogleOAuthProvider clientId="125529396413-8qts1abfjuv7j6mjc8k6fs7mct4ad0a8.apps.googleusercontent.com">
      <StoreProvider>
        {/* <WagmiConfig config={wagmiConfig}> uncomment if wallet login needed*/}
        <Router />
        <ToastContainer />
        {/* </WagmiConfig> */}
      </StoreProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
