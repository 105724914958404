export const FETCH_WEEK_GAME_PRIZES_START = 'FETCH_WEEK_GAME_PRIZES_START';
export const FETCH_WEEK_GAME_PRIZES_SUCCESS = 'FETCH_WEEK_GAME_PRIZES_SUCCESS';
export const FETCH_WEEK_GAME_PRIZES_FAIL = 'FETCH_WEEK_GAME_PRIZES_FAIL';

export const FETCH_WEEK_GAME_LEADERBOARD_START =
  'FETCH_WEEK_GAME_LEADERBOARD_START';
export const FETCH_WEEK_GAME_LEADERBOARD_SUCCESS =
  'FETCH_WEEK_GAME_LEADERBOARD_SUCCESS';
export const FETCH_WEEK_GAME_LEADERBOARD_FAIL =
  'FETCH_WEEK_GAME_LEADERBOARD_FAIL';

export const FETCH_CURRENT_GAME_WEEK_START = 'FETCH_CURRENT_GAME_WEEK_START';
export const FETCH_CURRENT_GAME_WEEK_SUCCESS =
  'FETCH_CURRENT_GAME_WEEK_SUCCESS';
export const FETCH_CURRENT_GAME_WEEK_FAIL = 'FETCH_CURRENT_GAME_WEEK_FAIL';

export const FETCH_GAME_WEEK_PERIOD_START = 'FETCH_GAME_WEEK_PERIOD_START';
export const FETCH_GAME_WEEK_PERIOD_SUCCESS = 'FETCH_GAME_WEEK_PERIOD_SUCCESS';
export const FETCH_GAME_WEEK_PERIOD_FAIL = 'FETCH_GAME_WEEK_PERIOD_FAIL';

export const RESET_WEEK_GAME_STORE = 'RESET_WEEK_GAME_STORE';

export type WeekGameActionTypes =
  | typeof FETCH_WEEK_GAME_PRIZES_START
  | typeof FETCH_WEEK_GAME_PRIZES_SUCCESS
  | typeof FETCH_WEEK_GAME_PRIZES_FAIL
  | typeof FETCH_WEEK_GAME_LEADERBOARD_START
  | typeof FETCH_WEEK_GAME_LEADERBOARD_SUCCESS
  | typeof FETCH_WEEK_GAME_LEADERBOARD_FAIL
  | typeof FETCH_CURRENT_GAME_WEEK_START
  | typeof FETCH_CURRENT_GAME_WEEK_SUCCESS
  | typeof FETCH_CURRENT_GAME_WEEK_FAIL
  | typeof FETCH_GAME_WEEK_PERIOD_START
  | typeof FETCH_GAME_WEEK_PERIOD_SUCCESS
  | typeof FETCH_GAME_WEEK_PERIOD_FAIL
  | typeof RESET_WEEK_GAME_STORE;
