import * as actionTypes from './actionTypes';
import { BalanceHistoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { BalanceHistory } from '../../domain/BalanceHistory';
import { ListResults } from '../../common/List/List';

export type BalanceHistoryStateType = {
  playerBalanceHistoryError: HttpError;
  playerBalanceHistoryList: ListResults<BalanceHistory> | null;
  playerBalanceHistoryLoading: boolean;
  playerBalanceHistoryUpdateNeeded: boolean;
  balanceHistoryError: HttpError;
  balanceHistory: BalanceHistory[] | null;
  balanceHistoryLoading: boolean;
  balanceHistoryUpdateNeeded: boolean;
};

export type BalanceHistoryActionType = BalanceHistoryStateType & {
  type: BalanceHistoryActionTypes;
};

export const initialState: BalanceHistoryStateType = {
  playerBalanceHistoryError: null,
  playerBalanceHistoryList: null,
  playerBalanceHistoryLoading: false,
  playerBalanceHistoryUpdateNeeded: false,
  balanceHistoryError: null,
  balanceHistory: null,
  balanceHistoryLoading: true,
  balanceHistoryUpdateNeeded: false,
};

const fetchPlayerBalanceHistoryStart = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...state,
  playerBalanceHistoryLoading: true,
});

const fetchPlayerBalanceHistorySuccess = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  playerBalanceHistoryList: action.playerBalanceHistoryList,
  playerBalanceHistoryLoading: false,
  playerBalanceHistoryError: null,
});

const fetchPlayerBalanceHistoryFail = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  playerBalanceHistoryError: action.playerBalanceHistoryError,
  playerBalanceHistoryLoading: false,
});

const resetPlayerBalanceHistoryStore = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...state,
  playerBalanceHistoryList: null,
  playerBalanceHistoryError: null,
  playerBalanceHistoryLoading: false,
});

const fetchBalanceHistoryStart = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoryLoading: true,
});

const fetchBalanceHistorySuccess = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistory: action.balanceHistory,
  balanceHistoryLoading: false,
  balanceHistoryError: null,
  balanceHistoryUpdateNeeded: false,
});

const fetchBalanceHistoryFail = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoryError: action.balanceHistoryError,
  balanceHistoryLoading: false,
});

const resetBalanceHistoryStore = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...initialState,
});

const completelyResetBalanceHistoryStore = (): BalanceHistoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BalanceHistoryActionType) => {
  switch (action.type) {
    case actionTypes.RESET_BALANCE_HISTORY_STORE:
      return resetBalanceHistoryStore(state);
    case actionTypes.FETCH_PLAYER_BALANCE_HISTORY_START:
      return fetchPlayerBalanceHistoryStart(state);
    case actionTypes.FETCH_PLAYER_BALANCE_HISTORY_SUCCESS:
      return fetchPlayerBalanceHistorySuccess(state, action);
    case actionTypes.FETCH_PLAYER_BALANCE_HISTORY_FAIL:
      return fetchPlayerBalanceHistoryFail(state, action);
    case actionTypes.RESET_PLAYER_BALANCE_HISTORY_STORE:
      return resetPlayerBalanceHistoryStore(state);
    case actionTypes.FETCH_BALANCE_HISTORY_START:
      return fetchBalanceHistoryStart(state);
    case actionTypes.FETCH_BALANCE_HISTORY_SUCCESS:
      return fetchBalanceHistorySuccess(state, action);
    case actionTypes.FETCH_BALANCE_HISTORY_FAIL:
      return fetchBalanceHistoryFail(state, action);
    case actionTypes.RESET_BALANCE_HISTORY_STORE:
      return resetBalanceHistoryStore(state);
    case actionTypes.LOGOUT:
      return completelyResetBalanceHistoryStore();
    default:
      return state;
  }
};

export default reducer;
