import React, { ReactNode } from 'react';
import styles from './Button.module.scss';
import cx from 'classnames';
import { LoadingButton } from '@mui/lab';
import { Button as MuiButton } from '@mui/material';

type Props = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  backgroundSizeCover?: boolean;
  isGreen?: boolean;
  isDisabled?: boolean;
  isRed?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'primary' | 'success' | 'warning' | 'danger';
  buttonVariant?: 'contained' | 'text' | 'outlined';
  isLoading?: boolean;
  isLoadingButton?: boolean;
  isConfirmationButton?: boolean;
  isSaveButton?: boolean;
  color?: 'primary' | 'secondary';
  startIcon?: string | ReactNode;
  endIcon?: string | ReactNode;
  isActive?: boolean;
  dissableRipple?: boolean;
  isJokerTreasureGameButton?: boolean;
  isTeal?: boolean;
  isTealDark?: boolean;
  isSecondaryRed?: boolean;
  isGameButton?: boolean;
};

export const Button = ({
  children,
  className,
  onClick,
  backgroundSizeCover,
  isGreen,
  isDisabled,
  isRed,
  isLoadingButton,
  isConfirmationButton,
  isSaveButton,
  color,
  startIcon,
  endIcon,
  isActive,
  dissableRipple,
  buttonVariant,
  isLoading,
  type,
  isJokerTreasureGameButton,
  isTeal,
  isTealDark,
  isSecondaryRed,
  isGameButton,
}: Props) => {
  if (isJokerTreasureGameButton) {
    const getButtonStyleClass = () => {
      if (isTeal) {
        return styles.tealButton;
      }

      if (isTealDark) {
        return styles.tealDarkButton;
      }

      if (isSecondaryRed) {
        return styles.secondaryRedButton;
      }

      return styles.jokerTreasureGameButton;
    };

    return (
      <button
        className={cx(
          styles.buttonContainer,
          styles.jokerTreasureGameButton,
          getButtonStyleClass(),
          className,
        )}
        onClick={onClick}
        disabled={isDisabled}
      >
        {children}
      </button>
    );
  }

  if (isDisabled && !isGameButton) {
    return (
      <button
        className={cx(styles.buttonContainer, styles.greyButton, className)}
        style={{
          backgroundSize: backgroundSizeCover ? 'cover' : 'auto',
        }}
        onClick={onClick}
        disabled={isDisabled}
      >
        {children}
      </button>
    );
  }

  if (isGreen) {
    return (
      <button
        className={cx(styles.buttonContainer, styles.greenButton, className)}
        style={{
          backgroundSize: backgroundSizeCover ? 'cover' : 'auto',
        }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  if (isRed) {
    return (
      <button
        className={cx(styles.buttonContainer, styles.redButton, className)}
        style={{
          backgroundSize: backgroundSizeCover ? 'cover' : 'auto',
        }}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  if (isLoadingButton) {
    return (
      <LoadingButton
        className={cx(styles.button, className)}
        onClick={onClick}
        variant={buttonVariant ?? 'contained'}
        color={color}
        loading={isLoading}
        loadingPosition={endIcon ? 'end' : undefined}
        startIcon={startIcon}
        endIcon={endIcon}
        type={type}
        disabled={isDisabled}
      >
        {children}
      </LoadingButton>
    );
  }

  if (isSaveButton) {
    return (
      <LoadingButton
        className={cx(styles.button, styles.saveButton, className)}
        onClick={onClick}
        variant={buttonVariant ?? 'contained'}
        color={color}
        loading={isLoading}
        loadingPosition={endIcon ? 'end' : undefined}
        endIcon={endIcon}
        type={type}
        disabled={isDisabled}
      >
        {children}
      </LoadingButton>
    );
  }

  if (isConfirmationButton) {
    return (
      <MuiButton
        className={cx(styles.button, styles.simpleButton)}
        onClick={onClick}
        variant={'text'}
        color={color}
        startIcon={startIcon}
        disabled={isLoading}
        disableRipple={dissableRipple}
      >
        {children}
      </MuiButton>
    );
  }

  return (
    <MuiButton
      className={cx(
        styles.button,
        {
          [styles.textButton]: buttonVariant === 'text',
          [styles.isActive]: isActive,
          [styles.onlyIcon]: !children,
        },
        className,
      )}
      onClick={onClick}
      variant={buttonVariant ?? 'contained'}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableRipple={dissableRipple}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
