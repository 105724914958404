export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_PLAYERS_START = 'FETCH_PLAYERS_START';
export const FETCH_PLAYERS_SUCCESS = 'FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_FAIL = 'FETCH_PLAYERS_FAIL';

export const SET_PLAYER_LIST_PARAMS = 'SET_PLAYER_LIST_PARAMS';

export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAIL = 'FETCH_ALL_USERS_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const RESET_USER_INFO_STORE = 'RESET_USER_INFO_STORE';

export const CHANGE_USER_PASSWORD_START = 'CHANGE_USER_PASSWORD_START';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD_FAIL';
export const RESET_CHANGE_USER_PASSWORD_STORE =
  'RESET_CHANGE_USER_PASSWORD_STORE';

export const UPDATE_USER_STATUS_START = 'UPDATE_USER_STATUS_START';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAIL = 'UPDATE_USER_STATUS_FAIL';

export const UPDATE_BALANCE_START = 'UPDATE_BALANCE_START';
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS';
export const UPDATE_BALANCE_FAIL = 'UPDATE_BALANCE_FAIL';

export const DEPOSIT_START = 'DEPOSIT_START';
export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
export const DEPOSIT_FAIL = 'DEPOSIT_FAIL';
export const DEPOSIT_RESET = 'DEPOSIT_RESET';

export const FETCH_INVITED_USERS_START = 'FETCH_INVITED_USERS_START';
export const FETCH_INVITED_USERS_SUCCESS = 'FETCH_INVITED_USERS_SUCCESS';
export const FETCH_INVITED_USERS_FAIL = 'FETCH_INVITED_USERS_FAIL';

export const FETCH_REF_OWNER_START = 'FETCH_REF_OWNER_START';
export const FETCH_REF_OWNER_SUCCESS = 'FETCH_REF_OWNER_SUCCESS';
export const FETCH_REF_OWNER_FAIL = 'FETCH_REF_OWNER_FAIL';

export const FETCH_TOTAL_PLAYERS_COINS_START =
  'FETCH_TOTAL_PLAYERS_COINS_START';
export const FETCH_TOTAL_PLAYERS_COINS_SUCCESS =
  'FETCH_TOTAL_PLAYERS_COINS_SUCCESS';
export const FETCH_TOTAL_PLAYERS_COINS_FAIL = 'FETCH_TOTAL_PLAYERS_COINS_FAIL';

export const RESET_USER_STORE = 'RESET_USER_STORE';
export const RESET_CREATED_USER = 'RESET_CREATED_USER';
export const RESET_UPDATED_USER_BALANCE_STORE =
  'RESET_UPDATED_USER_BALANCE_STORE';

export const LOGOUT = 'LOGOUT';

export type UserActionTypes =
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_ALL_USERS_START
  | typeof FETCH_ALL_USERS_SUCCESS
  | typeof FETCH_ALL_USERS_FAIL
  | typeof FETCH_USERS_START
  | typeof FETCH_USERS_SUCCESS
  | typeof FETCH_USERS_FAIL
  | typeof FETCH_PLAYERS_START
  | typeof FETCH_PLAYERS_SUCCESS
  | typeof FETCH_PLAYERS_FAIL
  | typeof SET_PLAYER_LIST_PARAMS
  | typeof CREATE_USER_START
  | typeof CREATE_USER_SUCCESS
  | typeof CREATE_USER_FAIL
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof DELETE_USER_START
  | typeof DELETE_USER_SUCCESS
  | typeof DELETE_USER_FAIL
  | typeof FETCH_ME_START
  | typeof FETCH_ME_SUCCESS
  | typeof FETCH_ME_FAIL
  | typeof UPDATE_USER_INFO_START
  | typeof UPDATE_USER_INFO_SUCCESS
  | typeof UPDATE_USER_INFO_FAIL
  | typeof CHANGE_USER_PASSWORD_START
  | typeof CHANGE_USER_PASSWORD_SUCCESS
  | typeof CHANGE_USER_PASSWORD_FAIL
  | typeof UPDATE_USER_STATUS_START
  | typeof UPDATE_USER_STATUS_SUCCESS
  | typeof UPDATE_USER_STATUS_FAIL
  | typeof UPDATE_BALANCE_START
  | typeof UPDATE_BALANCE_SUCCESS
  | typeof UPDATE_BALANCE_FAIL
  | typeof DEPOSIT_START
  | typeof DEPOSIT_SUCCESS
  | typeof DEPOSIT_FAIL
  | typeof DEPOSIT_RESET
  | typeof FETCH_INVITED_USERS_START
  | typeof FETCH_INVITED_USERS_SUCCESS
  | typeof FETCH_INVITED_USERS_FAIL
  | typeof FETCH_REF_OWNER_START
  | typeof FETCH_REF_OWNER_SUCCESS
  | typeof FETCH_REF_OWNER_FAIL
  | typeof FETCH_TOTAL_PLAYERS_COINS_START
  | typeof FETCH_TOTAL_PLAYERS_COINS_SUCCESS
  | typeof FETCH_TOTAL_PLAYERS_COINS_FAIL
  | typeof RESET_CHANGE_USER_PASSWORD_STORE
  | typeof RESET_USER_INFO_STORE
  | typeof RESET_USER_STORE
  | typeof RESET_CREATED_USER
  | typeof RESET_UPDATED_USER_BALANCE_STORE
  | typeof LOGOUT;
