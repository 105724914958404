import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './RegisterFormModal.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { RegistrationRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import { resetRegistration } from '../../../../store/auth/actions';
import { useParams } from 'react-router-dom';
import { User } from '../../../../domain/User';
import Loader from '../../../../common/Loader/Loader';
import Modal from '../../../../common/Modal/Modal';

type Props = {
  onRegister: (inputs: RegistrationRequest) => void;
  isLoading: boolean;
  error: HttpError;
  registrationSuccess: boolean;
  onResetRegistration: () => void;
  validatedUser: null | User;
  isOpen: boolean;
  onClose: () => void;
  setIsLoginModalOpen: (state: boolean) => void;
  refCode?: string;
  username?: string | null;
};

type FormInputs = {
  email: string;
  password: string;
  passwordRepeat: string;
  username: string;
  walletAddress: string;
};

export const RegisterFormModal = ({
  onRegister,
  isLoading,
  error,
  registrationSuccess,
  onResetRegistration,
  validatedUser,
  isOpen,
  onClose,
  setIsLoginModalOpen,
  refCode,
  username,
}: Props) => {
  const intl = useIntl();
  const { code } = useParams<{ code: string | undefined }>();
  const [isLoaded, setIsLoaded] = useState<boolean>();

  useEffect(() => {
    return () => onResetRegistration();
  }, []);

  useEffect(() => {
    if (!isLoading && (!code || (validatedUser && code))) {
      setIsLoaded(true);
    }
  }, [isLoading, validatedUser, code]);

  const INPUTS = [
    {
      name: 'username',
      label: translate(intl, 'REGISTRATION_FORM.USERNAME'),
      type: 'text',
      placeholder: translate(intl, 'REGISTRATION_FORM.PLACHEHOLDER_USERNAME'),
      required: true,
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 4,
        },
        {
          type: 'maxLength',
          parameter: 30,
        },
      ],
    },
    {
      name: 'email',
      label: translate(intl, 'REGISTRATION_FORM.EMAIL'),
      type: 'text',
      placeholder: translate(intl, 'REGISTRATION_FORM.PLACHEHOLDER_EMAIL'),
      required: true,
      validation: [
        {
          type: 'required',
        },
        {
          type: 'email',
        },
        {
          type: 'minLength',
          parameter: 4,
        },
        {
          type: 'maxLength',
          parameter: 60,
        },
      ],
    },
    {
      name: 'password',
      label: translate(intl, 'REGISTRATION_FORM.PASSWORD'),
      type: 'password',
      placeholder: translate(intl, 'REGISTRATION_FORM.PLACHEHOLDER_PASSWORD'),
      required: true,
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 8,
        },
        {
          type: 'maxLength',
          parameter: 30,
        },
      ],
    },
    {
      name: 'passwordRepeat',
      label: translate(intl, 'REGISTRATION_FORM.PASSWORD_REPEAT'),
      type: 'password',
      required: true,
      placeholder: translate(
        intl,
        'REGISTRATION_FORM.PLACHEHOLDER_PASSWORD_REPEAT',
      ),
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 8,
        },
        {
          type: 'maxLength',
          parameter: 30,
        },
      ],
    },
    {
      name: 'walletAddress',
      label: translate(intl, 'REGISTRATION_FORM.CRYPTO_ADDRESS'),
      type: 'text',
      placeholder: translate(
        intl,
        'REGISTRATION_FORM.PLACHEHOLDER_CRYPTO_ADDRESS',
      ),
      required: true,
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 26,
        },
        {
          type: 'maxLength',
          parameter: 62,
        },
      ],
    },
    {
      name: 'adult',
      label: translate(intl, 'REGISTRATION_FORM.ADULT'),
      type: 'checkbox',
      value: '0',
      validation: [
        {
          type: 'requiredCheckbox',
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onRegister({
      email: submitInputs.email,
      password: submitInputs.password,
      passwordRepeat: submitInputs.passwordRepeat,
      username: submitInputs.username,
      walletAddress: submitInputs.walletAddress,
      refCode: refCode,
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
    onCheckboxChange,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  const handleClose = () => {
    onClose();
  };

  if (!isLoaded && (isLoading || !validatedUser || !code) && !error) {
    return <Loader isLoading={isLoading} height="300" />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.modalContainer}
      title={translate(intl, 'REGISTRATION_FORM.TITLE')}
    >
      {username && (
        <div className={styles.invitation}>
          {translate(intl, 'REGISTRATION.INVITED_BY_REF_USER').replace(
            ':value',
            username,
          )}
        </div>
      )}
      <div className={styles.registrationForm}>
        <Form
          className={styles.form}
          error={error}
          onSubmit={onSubmit}
          scrollIntoView={false}
        >
          {registrationSuccess && (
            <Alert variant="success" capitalize={false}>
              {translate(intl, 'REGISTRATION_FORM.SUCCESS_MESSAGE')}
            </Alert>
          )}
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {translate(intl, globalError)}
            </Alert>
          )}
          {!registrationSuccess && (
            <>
              {inputs.map((input) => (
                <TextField
                  key={input.name}
                  onChange={onInputChange}
                  value={input.value?.toString() ?? ''}
                  label={input.label ?? ''}
                  errors={input.validationErrors ?? []}
                  onBlur={onLoseInputFocus}
                  name={input.name}
                  type={input.type}
                  onInputBlur={onInputBlur}
                  onCheckboxChange={onCheckboxChange}
                  placeholder={input.placeholder}
                  required={input.required}
                  inputLabelProps={{
                    shrink: true,
                    sx: {
                      marginTop: '-1rem',
                    },
                  }}
                />
              ))}
              <Button
                isLoadingButton
                onClick={onSubmit}
                buttonVariant="contained"
                color="primary"
                type="submit"
                isLoading={isLoading}
              >
                {translate(intl, 'REGISTRATION_FORM.SUBMIT')}
              </Button>
            </>
          )}
        </Form>
        <p className={styles.alreadyRegisteredContainer}>
          {translate(intl, 'REGISTRATION_FORM.ALREADY_REGISTERED')}
          <span
            className={styles.alreadyRegisteredLink}
            onClick={() => {
              setIsLoginModalOpen(true);
              onClose();
            }}
          >
            {translate(intl, 'REGISTRATION_FORM.LOGIN')}
          </span>
        </p>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.registrationError,
  isLoading: state.auth.registrationLoading,
  registrationSuccess: state.auth.registrationSuccess,
  validatedUser: state.auth.validatedUser,
  refCode: state.auth.refCode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onRegister: (inputs: RegistrationRequest) =>
    dispatch(authService.register(inputs)),
  onResetRegistration: () => dispatch(resetRegistration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFormModal);
