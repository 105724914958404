import * as actionTypes from './actionTypes';
import { AuthorizationCountryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { AuthorizationCountry } from '../../domain/AuthorizationCountry';

export type AuthorizationCountryStateType = {
  authorizationCountryList: ListResults<AuthorizationCountry> | null;
  authorizationCountryListLoading: boolean;
  authorizationCountryListError: HttpError;
  updatedAuthorizationCountryLoading: boolean;
  updatedAuthorizationCountrySuccess: boolean;
  updatedAuthorizationCountryError: HttpError;
  updatedAuthorizationCountry: AuthorizationCountry | null;
  countryAuthorizationLoading: boolean;
  countryAuthorizationSuccess: boolean;
  countryAuthorizationError: HttpError;
};

export type AuthorizationCountryActionType = AuthorizationCountryStateType & {
  type: AuthorizationCountryActionTypes;
};

export const initialState: AuthorizationCountryStateType = {
  authorizationCountryList: null,
  authorizationCountryListLoading: true,
  authorizationCountryListError: null,
  updatedAuthorizationCountryLoading: false,
  updatedAuthorizationCountryError: null,
  updatedAuthorizationCountrySuccess: false,
  updatedAuthorizationCountry: null,
  countryAuthorizationLoading: false,
  countryAuthorizationSuccess: false,
  countryAuthorizationError: null,
};

const fetchAuthorizationCountriesStart = (
  state: AuthorizationCountryStateType,
): AuthorizationCountryStateType => ({
  ...state,
  authorizationCountryListLoading: true,
});

const fetchAuthorizationCountriesSuccess = (
  state: AuthorizationCountryStateType,
  action: AuthorizationCountryActionType,
): AuthorizationCountryStateType => ({
  ...state,
  authorizationCountryList: action.authorizationCountryList,
  authorizationCountryListLoading: false,
  updatedAuthorizationCountryError: null,
});

const fetchAuthorizationCountriesFail = (
  state: AuthorizationCountryStateType,
  action: AuthorizationCountryActionType,
): AuthorizationCountryStateType => ({
  ...state,
  updatedAuthorizationCountryError: action.updatedAuthorizationCountryError,
  authorizationCountryListLoading: false,
});

const toggleAuthorizationCountryStart = (
  state: AuthorizationCountryStateType,
): AuthorizationCountryStateType => ({
  ...state,
  updatedAuthorizationCountryLoading: true,
});

const toggleAuthorizationCountrySuccess = (
  state: AuthorizationCountryStateType,
  action: AuthorizationCountryActionType,
): AuthorizationCountryStateType => ({
  ...state,
  updatedAuthorizationCountrySuccess: true,
  updatedAuthorizationCountryLoading: false,
  updatedAuthorizationCountryError: null,
  authorizationCountryList: state.authorizationCountryList
    ? {
        ...state.authorizationCountryList,
        result: state.authorizationCountryList.result.map((country) => {
          if (
            action.updatedAuthorizationCountry &&
            action.updatedAuthorizationCountry.id === country.id
          ) {
            return {
              ...country,
              isBanned: action.updatedAuthorizationCountry.isBanned,
            };
          }

          return country;
        }),
      }
    : null,
});

const toggleAuthorizationCountryFail = (
  state: AuthorizationCountryStateType,
  action: AuthorizationCountryActionType,
): AuthorizationCountryStateType => ({
  ...state,
  updatedAuthorizationCountryError: action.updatedAuthorizationCountryError,
  updatedAuthorizationCountryLoading: false,
});

const countryAuthorizationStart = (
  state: AuthorizationCountryStateType,
): AuthorizationCountryStateType => ({
  ...state,
  countryAuthorizationLoading: true,
});

const countryAuthorizationSuccess = (
  state: AuthorizationCountryStateType,
): AuthorizationCountryStateType => ({
  ...state,
  countryAuthorizationSuccess: true,
  countryAuthorizationLoading: false,
  updatedAuthorizationCountryError: null,
});

const countryAuthorizationFail = (
  state: AuthorizationCountryStateType,
  action: AuthorizationCountryActionType,
): AuthorizationCountryStateType => ({
  ...state,
  countryAuthorizationError: action.countryAuthorizationError,
  countryAuthorizationLoading: false,
  countryAuthorizationSuccess: false,
});

const resetAuthorizationCountryStore = (
  state: AuthorizationCountryStateType,
): AuthorizationCountryStateType => ({
  ...initialState,
  countryAuthorizationSuccess: state.countryAuthorizationSuccess,
});

const completelyResetAuthorizationCountryStore =
  (): AuthorizationCountryStateType => ({
    ...initialState,
  });

const reducer = (
  state = initialState,
  action: AuthorizationCountryActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_AUTHORIZATION_COUNTRIES_START:
      return fetchAuthorizationCountriesStart(state);
    case actionTypes.FETCH_AUTHORIZATION_COUNTRIES_SUCCESS:
      return fetchAuthorizationCountriesSuccess(state, action);
    case actionTypes.FETCH_AUTHORIZATION_COUNTRIES_FAIL:
      return fetchAuthorizationCountriesFail(state, action);
    case actionTypes.TOGGLE_AUTHORIZATION_COUNTRY_START:
      return toggleAuthorizationCountryStart(state);
    case actionTypes.TOGGLE_AUTHORIZATION_COUNTRY_SUCCESS:
      return toggleAuthorizationCountrySuccess(state, action);
    case actionTypes.TOGGLE_AUTHORIZATION_COUNTRY_FAIL:
      return toggleAuthorizationCountryFail(state, action);
    case actionTypes.AUTHORIZE_COUNTRY_START:
      return countryAuthorizationStart(state);
    case actionTypes.AUTHORIZE_COUNTRY_SUCCESS:
      return countryAuthorizationSuccess(state);
    case actionTypes.AUTHORIZE_COUNTRY_FAIL:
      return countryAuthorizationFail(state, action);
    case actionTypes.RESET_AUTHORIZATION_COUNTRY_STORE:
      return resetAuthorizationCountryStore(state);
    case actionTypes.COMPLETELY_RESET_AUTHORIZATION_COUNTRY_STORE:
      return completelyResetAuthorizationCountryStore();
    default:
      return state;
  }
};

export default reducer;
