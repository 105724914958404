import React from 'react';
import Modal from '../Modal/Modal';
import styles from './SuccessModal.module.scss';
import Button from '../Button/Button';
import successIcon from '../../assets/success.png';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  description?: string;
  buttonText: string;
};

const SuccessModal = ({
  isOpen,
  onClose,
  title,
  description,
  buttonText,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} image={successIcon}>
      <div className={styles.modalContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div>
          <Button
            onClick={onClose}
            buttonVariant="contained"
            color="primary"
            className={styles.closeButton}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
