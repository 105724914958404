import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  createUserStart,
  createUserSuccess,
  createUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFail,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  fetchAllUsersFail,
  fetchAllUsersStart,
  fetchAllUsersSuccess,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserInfoFail,
  changeUserPasswordStart,
  changeUserPasswordSuccess,
  changeUserPasswordFail,
  fetchPlayersStart,
  fetchPlayersSuccess,
  fetchPlayersFail,
  updateUserStatusStart,
  updateUserStatusSuccess,
  updateUserStatusFail,
  updateUserBalanceStart,
  updateUserBalanceSuccess,
  updateUserBalanceFail,
  depositStart,
  depositSuccess,
  depositFail,
  fetchInvitedUsersSuccess,
  fetchInvitedUsersFail,
  fetchInvitedUsersStart,
  fetchRefOwnerFail,
  fetchRefOwnerStart,
  fetchRefOwnerSuccess,
  fetchTotalPlayersCoinsSuccess,
  fetchTotalPlayersCoinsFail,
  fetchTotalPlayersCoinsStart,
  setPlayerListParams,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { User } from '../../domain/User';
import { getFormData } from '../../utility/form/formData';

export type UserCreateRequest = {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

export type UserUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
};

export type UserUpdateInfoRequest = {
  profileImage?: File;
  username?: string;
  email?: string;
  address?: string;
};

export type UserChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

export type UserBalanceUpdateRequest = {
  balance: number;
};

export type PlayersListParams = ListParams & {
  status?: boolean;
};

export type DepositRequest = {
  balance: number;
  hash: string;
};

const API_URL = '/users';

export const fetchUser = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err?.response?.data?.error));
    });
};

export const fetchUsers = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err?.response?.data?.error));
    });
};

export const fetchPlayers =
  (params: PlayersListParams) => (dispatch: Dispatch) => {
    dispatch(setPlayerListParams(params));
    dispatch(fetchPlayersStart());
    return axios
      .get(`${API_URL}/players`, { params })
      .then((response) => {
        dispatch(fetchPlayersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPlayersFail(err?.response?.data?.error));
      });
  };

export const fetchAllUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUsersStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchAllUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUsersFail(err?.response?.data?.error));
    });
};

export const createUser =
  (inputs: UserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.USER_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createUserFail(err?.response?.data?.message));
      });
  };

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserFail(err?.response?.data?.message));
      });
  };

export const updateUserInfo =
  (inputs: UserUpdateInfoRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserInfoStart());
    return axios
      .patch(`${API_URL}/update-info`, getFormData(inputs))
      .then((response) => {
        dispatch(updateUserInfoSuccess());
        dispatch(fetchCurrentUserSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.USER_INFO_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserInfoFail(err?.response?.data?.message));
      });
  };

export const changeUserPassword =
  (inputs: UserChangePasswordRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(changeUserPasswordStart());
    return axios
      .patch(`${API_URL}/change-password`, { ...inputs })
      .then(() => {
        dispatch(changeUserPasswordSuccess());
        showToast(translate(intl, 'SUCCESS.PASSWORD_CHANGE'), 'success');
      })
      .catch((err) => {
        dispatch(changeUserPasswordFail(err?.response?.data?.message));
      });
  };

export const deleteUser =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteUserStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteUserFail(err?.response?.data?.message));
      });
  };

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      const user = response.data as User;
      dispatch(fetchCurrentUserSuccess(user));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};

export const updateUserStatus =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStatusStart());
    return axios
      .patch(`${API_URL}/${id}/toggle-player`)
      .then((response) => {
        dispatch(updateUserStatusSuccess());
        showToast(translate(intl, 'SUCCESS.USER_INFO_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserStatusFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateUserBalance =
  (id: number, inputs: UserBalanceUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateUserBalanceStart());
    return axios
      .patch(`${API_URL}/${id}/update-balance`, { ...inputs })
      .then((response) => {
        dispatch(updateUserBalanceSuccess());
        showToast(translate(intl, 'SUCCESS.USER_BALANCE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserBalanceFail(err?.response?.data?.message));
      });
  };

export const deposit = (inputs: DepositRequest) => (dispatch: Dispatch) => {
  dispatch(depositStart());
  return axios
    .post(`${API_URL}/deposit`, { ...inputs })
    .then(() => {
      dispatch(depositSuccess());
    })
    .catch((err) => {
      dispatch(depositFail(err?.response?.data?.message));
    });
};

export const fetchInvitedUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchInvitedUsersStart());
  return axios
    .get(`${API_URL}/invited`)
    .then((response) => {
      dispatch(fetchInvitedUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchInvitedUsersFail(err?.response?.data?.error));
    });
};

export const getUsernameByRefCode =
  (refCode: string) => (dispatch: Dispatch) => {
    dispatch(fetchRefOwnerStart());

    return axios
      .get(`${API_URL}/ref-code/${refCode}`)
      .then((response) => {
        dispatch(fetchRefOwnerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchRefOwnerFail(err?.response?.data?.error));
      });
  };

export const fetchTotalPlayersCoins = () => (dispatch: Dispatch) => {
  dispatch(fetchTotalPlayersCoinsStart());
  return axios
    .get(`${API_URL}/coins-count`)
    .then((response) => {
      dispatch(fetchTotalPlayersCoinsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchTotalPlayersCoinsFail(err?.response?.data?.error));
    });
};
