import React, { useState } from 'react';
import styles from './Footer.module.scss';
import logo from '../../assets/GubyGame.svg';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';
import mailIcon from '../../assets/sms.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
// import { useWeb3Modal } from '@web3modal/wagmi/react' uncomment if wallet login needed;
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import AuthModals from '../PublicNavigation/AuthModals/AuthModals';

type Props = {
  isAuthenticated: boolean;
};

const Footer = ({ isAuthenticated }: Props) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] =
    useState<boolean>(false);

  const intl = useIntl();
  const navigate = useNavigate();
  // const { open } = useWeb3Modal() uncomment if wallet login needed;

  return (
    <footer className={styles.footer}>
      <hr className={styles.separatorLine} />
      <div className={styles.footerContainer}>
        <div className={styles.content}>
          <img src={logo} onClick={() => navigate(routes.homepage)} />
          <div className={styles.contactContainer}>
            <a
              className={styles.mailTo}
              href={`mailto:${translate(intl, 'FOOTER.EMAIL')}`}
            >
              <ReactSVG src={mailIcon} className={styles.mailIcon} />
              {translate(intl, 'FOOTER.EMAIL')}
            </a>
          </div>
          <div className={styles.footerLinks}>
            <div
              onClick={() => navigate(routes.policy)}
              className={styles.link}
            >
              {translate(intl, 'FOOTER.PRIVACY_POLICY')}
            </div>
            <div onClick={() => navigate(routes.rules)} className={styles.link}>
              {translate(intl, 'FOOTER.TERMS_AND_CONDITIONS')}
            </div>
            {!isAuthenticated && (
              <>
                <div
                  onClick={() => setIsLoginModalOpen(true)}
                  className={styles.link}
                >
                  {translate(intl, 'FOOTER.LOGIN')}
                </div>
                <div
                  onClick={() => setIsRegisterModalOpen(true)}
                  className={styles.link}
                >
                  {translate(intl, 'FOOTER.SIGN_UP')}
                </div>
              </>
            )}
          </div>
          <div className={styles.separationLine} />
          <div className={styles.allRightsReserved}>
            {translate(intl, 'FOOTER.ALL_RIGHTS_RESERVED')}
          </div>
        </div>
      </div>
      <AuthModals
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
      />
    </footer>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Footer);
