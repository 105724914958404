import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { BoxType, WarzoneGameRoom } from '../../domain/WarzoneGameRoom';
import { WarzoneGameRoomActionTypes } from './actionTypes';
import { WarzoneGameRoomData } from './service';

export type WarzoneGameRoomStateType = {
  warzoneGameRoomCreateLoading: boolean;
  warzoneGameRoomCreateError: HttpError;
  warzoneGameRoomCreateSuccess: boolean;
  createdWarzoneGameRoom: WarzoneGameRoom | null;
  warzoneGameRoom: WarzoneGameRoom | null;
  selectWarzoneBoxLoading: boolean;
  selectWarzoneBoxError: HttpError;
  nextWarzoneLevelLoading: boolean;
  nextWarzoneLevelError: HttpError;
  nextWarzoneLevelSuccess: boolean;
  previousWarzoneLevelLoading: boolean;
  previousWarzoneLevelError: HttpError;
  previousWarzoneLevelSuccess: boolean;
  weeksBestScore: number;
  weeksBestScoreError: HttpError;
  weeksBestScoreSuccess: boolean;
  weeksBestScoreLoading: boolean;
  warzoneGameRoomData: WarzoneGameRoomData | null;
  selectedBoxType: BoxType | null;
};

export type WarzoneGameRoomActionType = WarzoneGameRoomStateType & {
  type: WarzoneGameRoomActionTypes;
};

export const initialState: WarzoneGameRoomStateType = {
  warzoneGameRoomCreateLoading: false,
  warzoneGameRoomCreateError: null,
  warzoneGameRoomCreateSuccess: false,
  createdWarzoneGameRoom: null,
  warzoneGameRoom: null,
  selectWarzoneBoxLoading: false,
  selectWarzoneBoxError: null,
  nextWarzoneLevelLoading: false,
  nextWarzoneLevelError: null,
  nextWarzoneLevelSuccess: false,
  previousWarzoneLevelLoading: false,
  previousWarzoneLevelError: null,
  previousWarzoneLevelSuccess: false,
  weeksBestScore: 0,
  weeksBestScoreLoading: false,
  weeksBestScoreError: null,
  weeksBestScoreSuccess: false,
  selectedBoxType: null,
  warzoneGameRoomData: null,
};

const createWarzoneGameRoomStart = (
  state: WarzoneGameRoomStateType,
): WarzoneGameRoomStateType => ({
  ...state,
  warzoneGameRoomCreateLoading: true,
  warzoneGameRoomCreateSuccess: false,
  selectedBoxType: null,
});

const createWarzoneGameRoomSuccess = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  warzoneGameRoomCreateLoading: false,
  warzoneGameRoomCreateError: null,
  warzoneGameRoomCreateSuccess: true,
  createdWarzoneGameRoom: action.createdWarzoneGameRoom,
  warzoneGameRoom: action.createdWarzoneGameRoom,
});

const createWarzoneGameRoomFail = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  warzoneGameRoomCreateLoading: false,
  warzoneGameRoomCreateError: action.warzoneGameRoomCreateError,
});

const selectWarzoneBoxStart = (
  state: WarzoneGameRoomStateType,
): WarzoneGameRoomStateType => ({
  ...state,
  selectWarzoneBoxLoading: true,
});

const selectWarzoneBoxSuccess = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  selectWarzoneBoxLoading: false,
  warzoneGameRoom: action.warzoneGameRoomData?.warzoneGameRoom ?? null,
  selectedBoxType: action.warzoneGameRoomData?.boxType ?? null,
});

const selectWarzoneBoxFail = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  selectWarzoneBoxLoading: false,
  selectWarzoneBoxError: action.selectWarzoneBoxError,
});

const previousWarzoneLevelStart = (
  state: WarzoneGameRoomStateType,
): WarzoneGameRoomStateType => ({
  ...state,
  previousWarzoneLevelLoading: true,
  previousWarzoneLevelSuccess: false,
});

const previousWarzoneLevelSuccess = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  previousWarzoneLevelLoading: false,
  previousWarzoneLevelSuccess: true,
  warzoneGameRoom: action.warzoneGameRoom,
});

const previousWarzoneLevelFail = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  previousWarzoneLevelLoading: false,
  previousWarzoneLevelError: action.previousWarzoneLevelError,
});

const nextWarzoneLevelStart = (
  state: WarzoneGameRoomStateType,
): WarzoneGameRoomStateType => ({
  ...state,
  nextWarzoneLevelLoading: true,
  nextWarzoneLevelSuccess: false,
});

const nextWarzoneLevelSuccess = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  nextWarzoneLevelLoading: false,
  nextWarzoneLevelSuccess: true,
  warzoneGameRoom: action.warzoneGameRoom,
});

const nextWarzoneLevelFail = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  nextWarzoneLevelLoading: false,
  nextWarzoneLevelError: action.nextWarzoneLevelError,
});

const fetchWeeksBestScoreStart = (
  state: WarzoneGameRoomStateType,
): WarzoneGameRoomStateType => ({
  ...state,
  weeksBestScoreLoading: true,
});

const fetchWeeksBestScoreSuccess = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  weeksBestScoreLoading: false,
  weeksBestScore: action.weeksBestScore,
});

const fetchWeeksBestScoreFail = (
  state: WarzoneGameRoomStateType,
  action: WarzoneGameRoomActionType,
): WarzoneGameRoomStateType => ({
  ...state,
  weeksBestScoreLoading: false,
  weeksBestScoreError: action.weeksBestScoreError,
});

const resetSelectedWarzoneGameRoom = (
  state: WarzoneGameRoomStateType,
): WarzoneGameRoomStateType => ({
  ...state,
  warzoneGameRoomCreateLoading: false,
  warzoneGameRoomCreateError: null,
  warzoneGameRoomCreateSuccess: false,
  createdWarzoneGameRoom: null,
  warzoneGameRoom: null,
});

const resetGameRoomStore = (): WarzoneGameRoomStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: WarzoneGameRoomActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_WARZONE_GAME_ROOM_START:
      return createWarzoneGameRoomStart(state);
    case actionTypes.CREATE_WARZONE_GAME_ROOM_SUCCESS:
      return createWarzoneGameRoomSuccess(state, action);
    case actionTypes.CREATE_WARZONE_GAME_ROOM_FAIL:
      return createWarzoneGameRoomFail(state, action);
    case actionTypes.SELECT_WARZONE_BOX_START:
      return selectWarzoneBoxStart(state);
    case actionTypes.SELECT_WARZONE_BOX_SUCCESS:
      return selectWarzoneBoxSuccess(state, action);
    case actionTypes.SELECT_WARZONE_BOX_FAIL:
      return selectWarzoneBoxFail(state, action);
    case actionTypes.NEXT_WARZONE_LEVEL_START:
      return nextWarzoneLevelStart(state);
    case actionTypes.NEXT_WARZONE_LEVEL_SUCCESS:
      return nextWarzoneLevelSuccess(state, action);
    case actionTypes.NEXT_WARZONE_LEVEL_FAIL:
      return nextWarzoneLevelFail(state, action);
    case actionTypes.PREVIOUS_WARZONE_LEVEL_START:
      return previousWarzoneLevelStart(state);
    case actionTypes.PREVIOUS_WARZONE_LEVEL_SUCCESS:
      return previousWarzoneLevelSuccess(state, action);
    case actionTypes.PREVIOUS_WARZONE_LEVEL_FAIL:
      return previousWarzoneLevelFail(state, action);
    case actionTypes.FETCH_WARZONE_WEEKS_BEST_SCORE_START:
      return fetchWeeksBestScoreStart(state);
    case actionTypes.FETCH_WARZONE_WEEKS_BEST_SCORE_SUCCESS:
      return fetchWeeksBestScoreSuccess(state, action);
    case actionTypes.FETCH_WARZONE_WEEKS_BEST_SCORE_FAIL:
      return fetchWeeksBestScoreFail(state, action);
    case actionTypes.RESET_SELECTED_WARZONE_GAME_ROOM_STORE:
      return resetSelectedWarzoneGameRoom(state);
    case actionTypes.RESET_WARZONE_GAME_ROOM_STORE:
      return resetGameRoomStore();
    default:
      return state;
  }
};

export default reducer;
