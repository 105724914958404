import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { WeekGameActionTypes } from './actionTypes';
import {
  WeekGame,
  WeekGameLeaderboard,
  WeekGamePeriod,
  WeekGamePrizes,
} from '../../domain/WeekGame';

export type WeekGameStateType = {
  weekGamePrizes: WeekGamePrizes | null;
  weekGamePrizesLoading: boolean;
  weekGamePrizesError: HttpError;
  weekGameLeaderboard: WeekGameLeaderboard[] | null;
  weekGameLeaderboardLoading: boolean;
  weekGameLeaderboardError: HttpError;
  currentGameWeek: WeekGame | null;
  currentGameWeekLoading: boolean;
  currentGameWeekError: HttpError;
  weekGamePeriod: WeekGamePeriod | null;
  weekGamePeriodLoading: boolean;
  weekGamePeriodError: HttpError;
};

export type WeekGameActionType = WeekGameStateType & {
  type: WeekGameActionTypes;
};

export const initialState: WeekGameStateType = {
  weekGamePrizes: null,
  weekGamePrizesLoading: false,
  weekGamePrizesError: null,
  weekGameLeaderboard: null,
  weekGameLeaderboardLoading: false,
  weekGameLeaderboardError: null,
  currentGameWeek: null,
  currentGameWeekLoading: false,
  currentGameWeekError: null,
  weekGamePeriod: null,
  weekGamePeriodLoading: false,
  weekGamePeriodError: null,
};

const fetchWeekGamePrizesStart = (
  state: WeekGameStateType,
): WeekGameStateType => ({
  ...state,
  weekGamePrizesLoading: true,
});

const fetchWeekGamePrizesSuccess = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  weekGamePrizes: action.weekGamePrizes,
  weekGamePrizesLoading: false,
  weekGamePrizesError: null,
});

const fetchWeekGamePrizesFail = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  weekGamePrizesError: action.weekGamePrizesError,
  weekGamePrizesLoading: false,
});

const fetchCurrentGameWeekStart = (
  state: WeekGameStateType,
): WeekGameStateType => ({
  ...state,
  currentGameWeekLoading: true,
});

const fetchCurrentGameWeekSuccess = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  currentGameWeek: action.currentGameWeek,
  currentGameWeekLoading: false,
  currentGameWeekError: null,
});

const fetchCurrentGameWeekFail = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  currentGameWeekError: action.currentGameWeekError,
  currentGameWeekLoading: false,
});

const fetchWeekGameLeaderboardStart = (
  state: WeekGameStateType,
): WeekGameStateType => ({
  ...state,
  weekGameLeaderboardLoading: true,
});

const fetchWeekGameLeaderboardSuccess = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  weekGameLeaderboard: action.weekGameLeaderboard,
  weekGameLeaderboardLoading: false,
  weekGameLeaderboardError: null,
});

const fetchWeekGameLeaderboardFail = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  weekGameLeaderboardError: action.weekGameLeaderboardError,
  weekGameLeaderboardLoading: false,
});

const fetchWeekGamePeriodStart = (
  state: WeekGameStateType,
): WeekGameStateType => ({
  ...state,
  weekGamePeriodLoading: true,
});

const fetchWeekGamePeriodSuccess = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  weekGamePeriod: action.weekGamePeriod,
  weekGamePeriodLoading: false,
  weekGamePeriodError: null,
});

const fetchWeekGamePeriodFail = (
  state: WeekGameStateType,
  action: WeekGameActionType,
): WeekGameStateType => ({
  ...state,
  weekGamePeriodError: action.weekGamePeriodError,
  weekGamePeriodLoading: false,
});

const resetWeekGameStore = (state: WeekGameStateType): WeekGameStateType => ({
  ...initialState,
  weekGamePrizes: state.weekGamePrizes,
  currentGameWeek: state.currentGameWeek,
  weekGamePeriod: state.weekGamePeriod,
});

const reducer = (state = initialState, action: WeekGameActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WEEK_GAME_PRIZES_START:
      return fetchWeekGamePrizesStart(state);
    case actionTypes.FETCH_WEEK_GAME_PRIZES_SUCCESS:
      return fetchWeekGamePrizesSuccess(state, action);
    case actionTypes.FETCH_WEEK_GAME_PRIZES_FAIL:
      return fetchWeekGamePrizesFail(state, action);
    case actionTypes.FETCH_WEEK_GAME_LEADERBOARD_START:
      return fetchWeekGameLeaderboardStart(state);
    case actionTypes.FETCH_WEEK_GAME_LEADERBOARD_SUCCESS:
      return fetchWeekGameLeaderboardSuccess(state, action);
    case actionTypes.FETCH_WEEK_GAME_LEADERBOARD_FAIL:
      return fetchWeekGameLeaderboardFail(state, action);
    case actionTypes.FETCH_CURRENT_GAME_WEEK_START:
      return fetchCurrentGameWeekStart(state);
    case actionTypes.FETCH_CURRENT_GAME_WEEK_SUCCESS:
      return fetchCurrentGameWeekSuccess(state, action);
    case actionTypes.FETCH_CURRENT_GAME_WEEK_FAIL:
      return fetchCurrentGameWeekFail(state, action);
    case actionTypes.FETCH_GAME_WEEK_PERIOD_START:
      return fetchWeekGamePeriodStart(state);
    case actionTypes.FETCH_GAME_WEEK_PERIOD_SUCCESS:
      return fetchWeekGamePeriodSuccess(state, action);
    case actionTypes.FETCH_GAME_WEEK_PERIOD_FAIL:
      return fetchWeekGamePeriodFail(state, action);
    case actionTypes.RESET_WEEK_GAME_STORE:
      return resetWeekGameStore(state);
    default:
      return state;
  }
};

export default reducer;
