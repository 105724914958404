import React, { useState, useEffect } from 'react';
import LoginFormModal from '../../../component/Public/Auth/LoginFormModal/LoginFormModal';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import RegisterFormModal from '../../../component/Public/Auth/RegisterFormModal/RegisterFormModal';
import RemindPasswordModal from '../../../component/Public/Auth/ReminPasswordModal/RemindPasswordModal';
import { User } from '../../../domain/User';
import { Roles } from '../../../domain/Role';

export type Props = {
  isRegistrationSuccess: boolean;
  setIsLoginModalOpen: (state: boolean) => void;
  isLoginModalOpen: boolean;
  isRemindPasswordSuccess: boolean;
  isAuthenticated: boolean;
  currentUser: User | null;
  setIsResetPasswordModalOpen?: (state: boolean) => void;
  refOwner?: string | null;
  setIsRegisterModalOpen?: (state: boolean) => void;
  isRegisterModalOpen?: boolean;
};

const AuthModals = ({
  isRegistrationSuccess,
  setIsLoginModalOpen,
  isLoginModalOpen,
  isRemindPasswordSuccess,
  isAuthenticated,
  currentUser,
  setIsResetPasswordModalOpen,
  refOwner,
  setIsRegisterModalOpen,
  isRegisterModalOpen,
}: Props) => {
  const [isRegisterFormModalOpen, setIsRegisterFormModalOpen] =
    useState<boolean>(false);
  const [isRemindPasswordModalOpen, setIsRemindPasswordModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (refOwner) {
      setIsRegisterFormModalOpen(true);
    }
  }, [refOwner]);

  useEffect(() => {
    if (isRegistrationSuccess) {
      setIsRegisterFormModalOpen(false);
    }
  }, [isRegistrationSuccess]);

  useEffect(() => {
    if (isRemindPasswordSuccess) {
      setIsRemindPasswordModalOpen(false);
    }
  }, [isRemindPasswordSuccess]);

  useEffect(() => {
    if (isAuthenticated && currentUser?.role === Roles.USER) {
      setIsLoginModalOpen(false);
      setIsResetPasswordModalOpen && setIsResetPasswordModalOpen(false);
      localStorage.removeItem('code');
    }
  }, [isAuthenticated]);

  const isRegisterOpen = isRegisterModalOpen || isRegisterFormModalOpen;

  const handleRegisterModalClose = (state: boolean) => {
    setIsRegisterFormModalOpen(state);

    if (setIsRegisterModalOpen) {
      setIsRegisterModalOpen(state);
    }
  };

  return (
    <>
      {isLoginModalOpen && (
        <LoginFormModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          setIsRegisterFormModalOpen={setIsRegisterFormModalOpen}
          setIsRemindPasswordModalOpen={setIsRemindPasswordModalOpen}
        />
      )}
      {isRegisterOpen && (
        <RegisterFormModal
          isOpen={isRegisterOpen}
          onClose={() => handleRegisterModalClose(false)}
          setIsLoginModalOpen={setIsLoginModalOpen}
          username={refOwner}
        />
      )}

      {isRemindPasswordModalOpen && (
        <RemindPasswordModal
          isOpen={isRemindPasswordModalOpen}
          onClose={() => setIsRemindPasswordModalOpen(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.user.currentUser,
  isRegistrationSuccess: state.auth.registrationSuccess,
  isRemindPasswordSuccess: state.auth.isRemindSuccess,
});

export default connect(mapStateToProps)(AuthModals);
