import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Queue } from '../../domain/Queue';
import { QueueActionTypes } from './actionTypes';

export type QueueStateType = {
  queuesList: Queue[] | null;
  queuesLoading: boolean;
  queuesError: HttpError;
  queueCreateLoading: boolean;
  queueCreateError: HttpError;
  queueCreateSuccess: boolean;
  queue: Queue | null;
  queueUpdateLoading: boolean;
  queueUpdateError: HttpError;
  queueUpdateSuccess: boolean;
};

export type QueueActionType = QueueStateType & {
  type: QueueActionTypes;
};

export const initialState: QueueStateType = {
  queuesList: null,
  queuesLoading: false,
  queuesError: null,
  queueCreateLoading: false,
  queueCreateError: null,
  queueCreateSuccess: false,
  queue: null,
  queueUpdateLoading: false,
  queueUpdateError: null,
  queueUpdateSuccess: false,
};

const fetchQueuesStart = (state: QueueStateType): QueueStateType => ({
  ...state,
  queuesLoading: true,
});

const fetchQueuesSuccess = (
  state: QueueStateType,
  action: QueueActionType,
): QueueStateType => ({
  ...state,
  queuesList: action.queuesList,
  queuesLoading: false,
  queuesError: null,
});

const fetchQueuesFail = (
  state: QueueStateType,
  action: QueueActionType,
): QueueStateType => ({
  ...state,
  queuesError: action.queuesError,
  queuesLoading: false,
});

const createQueueStart = (state: QueueStateType): QueueStateType => ({
  ...state,
  queueCreateLoading: true,
  queueCreateSuccess: false,
});

const createQueueSuccess = (
  state: QueueStateType,
  action: QueueActionType,
): QueueStateType => ({
  ...state,
  queueCreateLoading: false,
  queueCreateError: null,
  queueCreateSuccess: true,
  queue: action.queue,
});

const createQueueFail = (
  state: QueueStateType,
  action: QueueActionType,
): QueueStateType => ({
  ...state,
  queueCreateLoading: false,
  queueCreateError: action.queueCreateError,
});

const updateQueueStart = (state: QueueStateType): QueueStateType => ({
  ...state,
  queueUpdateLoading: true,
  queueUpdateSuccess: false,
});

const updateQueueSuccess = (
  state: QueueStateType,
  action: QueueActionType,
): QueueStateType => ({
  ...state,
  queueUpdateLoading: false,
  queueUpdateError: null,
  queueUpdateSuccess: true,
  queue: action.queue,
});

const updateQueueFail = (
  state: QueueStateType,
  action: QueueActionType,
): QueueStateType => ({
  ...state,
  queueUpdateLoading: false,
  queueUpdateError: action.queueUpdateError,
});

const resetQueueStore = (): QueueStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: QueueActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_QUEUES_START:
      return fetchQueuesStart(state);
    case actionTypes.FETCH_QUEUES_SUCCESS:
      return fetchQueuesSuccess(state, action);
    case actionTypes.FETCH_QUEUES_FAIL:
      return fetchQueuesFail(state, action);
    case actionTypes.CREATE_QUEUE_START:
      return createQueueStart(state);
    case actionTypes.CREATE_QUEUE_SUCCESS:
      return createQueueSuccess(state, action);
    case actionTypes.CREATE_QUEUE_FAIL:
      return createQueueFail(state, action);
    case actionTypes.UPDATE_QUEUE_START:
      return updateQueueStart(state);
    case actionTypes.UPDATE_QUEUE_SUCCESS:
      return updateQueueSuccess(state, action);
    case actionTypes.UPDATE_QUEUE_FAIL:
      return updateQueueFail(state, action);
    case actionTypes.RESET_QUEUE_STORE:
      return resetQueueStore();
    default:
      return state;
  }
};

export default reducer;
