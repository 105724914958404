import axios from '../../config/Axios/axios-instance';
import {
  loginGoogleStart,
  loginGoogleSuccess,
  loginGoogleFail,
  logout,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFail,
  loginStart,
  loginSuccess,
  loginFail,
  walletConnectLoginStart,
  walletConnectLoginFail,
  walletConnectLoginSuccess,
  emailConfirmStart,
  emailConfirmSuccess,
  emailConfirmFail,
  remindSuccess,
  registerConfirmFail,
  registerConfirmStart,
  registerConfirmSuccess,
  registrationFail,
  registrationStart,
  registrationSuccess,
  remindFail,
  remindStart,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  validateCodeFail,
  validateCodeStart,
  validateCodeSuccess,
  loginVerificationStart,
  loginVerificationSuccess,
  loginVerificationFail,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

const API_URL = '/auth';

export type LoginGoogleRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  token: string;
};

export type RegisterGoogleRequest = LoginGoogleRequest;

export type LoginVerificationRequest = {
  email: string;
  password: string;
};

export type LoginRequest = {
  email: string;
  verificationCode: number;
};

export type RegistrationRequest = {
  email: string;
  password: string;
  passwordRepeat: string;
  username: string;
  walletAddress: string;
  refCode?: string;
};

export type ConfirmEmailRequest = {
  code: string;
};

export type ValidateCodeRequest = {
  code: string;
};

export type RemindRequest = {
  email: string;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  passwordRepeat: string;
  code: string;
};

export type WalletConnectRequest = {
  address: string;
  signature: string;
  refCode?: string;
};

export type ConfirmRegistrationRequest = {
  code: string;
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios
      .post(`${API_URL}/google`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(loginGoogleSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err?.response?.data?.message));
      });
  };

export const refreshToken = () => (dispatch: Dispatch) => {
  dispatch(refreshTokenStart());
  return axios
    .post(`${API_URL}/refresh`)
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(refreshTokenSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(refreshTokenFail(err?.response?.data?.message));
    });
};

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(refreshTokenSuccess(token)) : dispatch(logout());
};

export const login = (inputs: LoginRequest) => (dispatch: Dispatch) => {
  dispatch(loginStart());
  return axios
    .post(`${API_URL}/login`, {
      ...inputs,
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(loginSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(loginFail(err?.response?.data?.message));
    });
};

export const loginVerification =
  (inputs: LoginVerificationRequest) => (dispatch: Dispatch) => {
    dispatch(loginVerificationStart());
    return axios
      .post(`${API_URL}/login-verification`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(loginVerificationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(loginVerificationFail(err?.response?.data?.message));
      });
  };

export const signWalletMessage =
  (signMessage: (message: string) => void) => (dispatch: Dispatch) => {
    dispatch(walletConnectLoginStart());
    localStorage.removeItem('token');

    return axios
      .get(`${API_URL}/message-to-sign`)
      .then(async (response) => signMessage(response.data))
      .catch((err) => {
        walletConnectLoginFail(err?.response?.data?.message);
      });
  };

export const walletConnectLogin =
  (request: WalletConnectRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    return axios
      .post(`${API_URL}/wallet-connect`, {
        ...request,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(walletConnectLoginSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(walletConnectLoginFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const confirmEmail =
  (inputs: ConfirmEmailRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(emailConfirmStart());
    return axios
      .post(`${API_URL}/confirm-email`, {
        ...inputs,
      })
      .then(async () => {
        dispatch(emailConfirmSuccess());
        showToast(translate(intl, 'SUCCESS.EMAIL_CONFIRMATION'), 'success');
      })
      .catch((err) => {
        dispatch(emailConfirmFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const register =
  (inputs: RegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registrationStart());
    return axios
      .post(`${API_URL}/register`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(registrationSuccess());
      })
      .catch((err) => {
        dispatch(registrationFail(err?.response?.data?.message));
      });
  };

export const confirmRegistration =
  (inputs: ConfirmRegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registerConfirmStart());
    return axios
      .post(`${API_URL}/confirm-registration`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(registerConfirmSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(registerConfirmFail(err?.response?.data?.message));
      });
  };

export const remind = (inputs: RemindRequest) => (dispatch: Dispatch) => {
  dispatch(remindStart());
  return axios
    .post(`${API_URL}/remind`, {
      ...inputs,
    })
    .then(async (response) => {
      dispatch(remindSuccess());
    })
    .catch((err) => {
      dispatch(remindFail(err?.response?.data?.message));
    });
};

export const resetPassword =
  (inputs: ResetPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    return axios
      .patch(`${API_URL}/reset-password`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(resetPasswordSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(resetPasswordFail(err?.response?.data?.message));
      });
  };

export const validateCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateCodeStart());
    return axios
      .post(`${API_URL}/validate-confirmation-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateCodeFail(err?.response?.data?.message));
      });
  };

export const logoutUser = () => (dispatch: Dispatch) => {
  localStorage.removeItem('@w3m/connected_wallet_image_url');
  dispatch(logout());
};
