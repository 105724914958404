import axios from 'axios';

export type JwtToken = {
  email: string;
  iat: number;
  exp: number;
};

export type HttpError =
  | null
  | string
  | Array<{ [key: string]: string }>
  | Array<{ field: string; message: string }>;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
