export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';

export const FETCH_PUBLIC_CATEGORIES_START = 'FETCH_PUBLIC_CATEGORIES_START';
export const FETCH_PUBLIC_CATEGORIES_SUCCESS =
  'FETCH_PUBLIC_CATEGORIES_SUCCESS';
export const FETCH_PUBLIC_CATEGORIES_FAIL = 'FETCH_PUBLIC_CATEGORIES_FAIL';

export const RESET_CATEGORY_STORE = 'RESET_CATEGORY_STORE';

export const LOGOUT = 'LOGOUT';

export type CategoryActionTypes =
  | typeof FETCH_CATEGORIES_START
  | typeof FETCH_CATEGORIES_SUCCESS
  | typeof FETCH_CATEGORIES_FAIL
  | typeof FETCH_PUBLIC_CATEGORIES_START
  | typeof FETCH_PUBLIC_CATEGORIES_SUCCESS
  | typeof FETCH_PUBLIC_CATEGORIES_FAIL
  | typeof RESET_CATEGORY_STORE
  | typeof LOGOUT;
