export const routes = {
  homepage: '/',
  refCode: '/refcode/:refCode',
  publicGames: '/games',
  policy: '/policy',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  players: {
    list: '/admin/players',
    edit: '/admin/players/:id',
  },
  cashouts: {
    list: '/admin/cashouts',
  },
  authorizationCountries: '/admin/authorization-countries',
  translations: '/admin/translations',
  profile: '/profile',
  emailConfirmation: '/email-confirmation/:code',
  games: {
    list: '/admin/games',
    create: '/admin/games/new',
    edit: '/admin/games/:id',
  },
  vip: '/profile/vip',
  singleGame: '/single-game/:slug',
  cashout: '/cashout',
  resetPassword: '/password-reset/:code',
  rules: '/terms-and-conditions',
  registrationConfirmation: '/registration-confirmation/:code',
};
