export const FETCH_QUEUES_START = 'FETCH_QUEUES_START';
export const FETCH_QUEUES_SUCCESS = 'FETCH_QUEUES_SUCCESS';
export const FETCH_QUEUES_FAIL = 'FETCH_QUEUES_FAIL';

export const CREATE_QUEUE_START = 'CREATE_QUEUE_START';
export const CREATE_QUEUE_SUCCESS = 'CREATE_QUEUE_SUCCESS';
export const CREATE_QUEUE_FAIL = 'CREATE_QUEUE_FAIL';

export const UPDATE_QUEUE_START = 'UPDATE_QUEUE_START';
export const UPDATE_QUEUE_SUCCESS = 'UPDATE_QUEUE_SUCCESS';
export const UPDATE_QUEUE_FAIL = 'UPDATE_QUEUE_FAIL';

export const RESET_QUEUE_STORE = 'RESET_QUEUE_STORE';

export type QueueActionTypes =
  | typeof FETCH_QUEUES_START
  | typeof FETCH_QUEUES_SUCCESS
  | typeof FETCH_QUEUES_FAIL
  | typeof CREATE_QUEUE_START
  | typeof CREATE_QUEUE_SUCCESS
  | typeof CREATE_QUEUE_FAIL
  | typeof UPDATE_QUEUE_START
  | typeof UPDATE_QUEUE_SUCCESS
  | typeof UPDATE_QUEUE_FAIL
  | typeof RESET_QUEUE_STORE;
