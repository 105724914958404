import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './VerificationModal.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import TextField from '../../../../common/TextField/TextField';
import Modal from '../../../../common/Modal/Modal';
import { LoginRequest } from '../../../../store/auth/service';
import { resetLoginVerification } from '../../../../store/auth/actions';

type Props = {
  onLogin: (inputs: LoginRequest) => void;
  isLoading: boolean;
  error: HttpError;
  isOpen: boolean;
  onClose: () => void;
  onResetLoginVerificationStore: () => void;
  userEmail: string | null;
};

type FormInputs = {
  verificationCode: number;
};

export const VerificationModal = ({
  isLoading,
  error,
  isOpen,
  onClose,
  onResetLoginVerificationStore,
  onLogin,
  userEmail,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    return () => onResetLoginVerificationStore();
  }, []);

  const INPUTS = [
    {
      name: 'verificationCode',
      label: translate(intl, 'LOGIN_VERIFICATION_FORM.CODE'),
      type: 'text',
      required: true,
      placeholder: translate(intl, 'LOGIN_VERIFICATION_FORM.CODE_PLACEHOLDER'),
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 6,
        },
        {
          type: 'maxLength',
          parameter: 6,
        },
        {
          type: 'isNumber',
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    userEmail &&
      onLogin({
        email: userEmail,
        verificationCode: submitInputs.verificationCode,
      });
  };

  const handleClose = () => {
    onClose();
  };

  const {
    inputs,
    onSubmit,
    onNumberInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.modalContainer}
      title={translate(intl, 'LOGIN_VERIFICATION.TITLE')}
    >
      <div className={styles.verificationForm}>
        <div className={styles.info}>
          {translate(intl, 'LOGIN_VERIFICATION.INFO')}
        </div>
        <Form
          className={styles.form}
          error={error}
          onSubmit={onSubmit}
          scrollIntoView={false}
        >
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {translate(intl, globalError)}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onNumberInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              placeholder={input.placeholder}
              type={input.type}
              required={input.required}
              onInputBlur={onInputBlur}
              inputLabelProps={{
                shrink: true,
                sx: {
                  marginTop: '-1rem',
                },
              }}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'LOGIN_VERIFICATION_FORM.SUBMIT')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.loginError,
  isLoading: state.auth.loginLoading,
  userEmail: state.auth.loginVerificationEmail,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogin: (inputs: LoginRequest) => dispatch(authService.login(inputs)),
  onResetLoginVerificationStore: () => dispatch(resetLoginVerification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal);
