import React, { ChangeEvent, ReactNode, useEffect, useRef } from 'react';
import styles from './FileInput.module.scss';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import {
  faFileCircleCheck,
  faFileCircleExclamation,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Asset } from '../../domain/Asset';
import { HttpError } from '../../config/Axios/axios-instance';
import Dropzone from 'react-dropzone';
import { Clear } from '@mui/icons-material';
import { ReactSVG } from 'react-svg';
import uploadDocument from '../../assets/icons/document-upload.svg';

type Props = {
  name: string;
  multiple?: boolean;
  value: File | File[] | string | string[] | undefined;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onFileDropped?: (name: string, files: File[]) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  maxFileCount?: number;
  errors?: Array<string>;
  label?: string | ReactNode;
  helperText?: string;
  asset?: Asset | null;
  minimalistic?: boolean;
};

const FileInput = ({
  onFileChange,
  value,
  name,
  maxFileCount,
  multiple,
  errors,
  label,
  helperText,
  asset,
  onImageDelete,
  onFileDropped,
  onSetValidationErrors,
  minimalistic,
}: Props) => {
  const intl = useIntl();

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const getIcon = () => {
    if (errors && errors.length > 0) {
      return faFileCircleExclamation;
    }

    if (value && (!errors || errors.length === 0)) {
      return faFileCircleCheck;
    }

    return faFileImport;
  };

  const renderAddFileButton = () => {
    const hasValue = Array.isArray(value) ? !!value.length : !!value;
    const uploadedCount = Array.isArray(value) && value.length;

    return (
      <div className={styles.innerFileSelectContainer}>
        <FontAwesomeIcon
          className={cx(styles.innerFileIcon, {
            [styles.errorFile]: errors && errors.length > 0,
            [styles.successFile]: hasValue && (!errors || errors.length === 0),
          })}
          icon={getIcon()}
          fixedWidth
          size="2x"
        />
        <span className={styles.innerFileSelectLabel}>
          {hasValue &&
          (!errors || errors.length === 0) &&
          (!multiple || (maxFileCount && uploadedCount === maxFileCount))
            ? translate(intl, 'INPUTS.READY_TO_UPLOAD')
            : translate(
                intl,
                multiple ? 'INPUTS.UPLOAD_IMAGES' : 'INPUTS.UPLOAD_IMAGE',
              )}
        </span>
      </div>
    );
  };

  const renderImage = (value: File | string, index: number) => {
    return (
      <div key={`image-${index}`} className={styles.imageContainer}>
        {multiple && (
          <div className={styles.delete}>
            <Clear
              color="inherit"
              onClick={() => {
                onImageDelete?.(name, value);

                if (uploadInputRef?.current) {
                  uploadInputRef.current.value = '';
                }
              }}
            />
          </div>
        )}
        {value instanceof File ? (
          <img src={URL.createObjectURL(value)} alt={value.name} />
        ) : (
          <img src={value.toString()} alt={value?.toString() || ''} />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      multiple &&
      maxFileCount &&
      Array.isArray(value) &&
      maxFileCount < value.length &&
      onSetValidationErrors
    ) {
      onSetValidationErrors([
        {
          field: name,
          message: translate(intl, 'VALIDATION.TOO_MANY_FILES'),
        },
      ]);
    }
  }, [value, multiple, maxFileCount]);

  return minimalistic ? (
    <Dropzone
      multiple
      onDrop={(acceptedFiles) => onFileDropped?.(name, acceptedFiles)}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={styles.fileFieldContainer} {...getRootProps()}>
          <div className={styles.fileField}>
            <input
              color="primary"
              type="file"
              onChange={onFileChange}
              ref={uploadInputRef}
              name={name}
              multiple={multiple}
              {...getInputProps()}
            />
            <div className={styles.minimalisticInfoContainer}>
              <div className={styles.mainInfo}>
                <ReactSVG src={uploadDocument} />
                <div>
                  <div className={styles.addFile}>{'Pridėti failą'}</div>
                  <div className={styles.info}>
                    {translate(intl, 'FILE_INPUT.MINI_ALLOWED_EXTENSIONS')}
                  </div>
                  <div className={styles.info}>
                    {`${translate(
                      intl,
                      'FILE_INPUT.MINI_ALLOWED_FORMAT',
                    )} | ${translate(intl, 'FILE_INPUT.MINI_MAX_FILE_SIZE')}`}
                  </div>
                  <p className={styles.uploadInfo}>
                    {value && (
                      <FontAwesomeIcon
                        icon={faFileImport as IconProp}
                        fixedWidth
                        size="sm"
                        className={styles.selectedFileIcon}
                      />
                    )}
                    <span className={styles.uploadInfo}>
                      {value && value instanceof File && value.name}
                    </span>
                  </p>
                  {errors &&
                    errors.map((error, index) => (
                      <div
                        className={styles.fileError}
                        key={`file-error-${index}`}
                      >
                        {error}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  ) : (
    <Dropzone
      multiple
      onDrop={(acceptedFiles) => onFileDropped?.(name, acceptedFiles)}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={styles.fileFieldContainer} {...getRootProps()}>
          <div className={styles.fileField}>
            <input
              color="primary"
              type="file"
              onChange={onFileChange}
              ref={uploadInputRef}
              name={name}
              multiple={multiple}
              {...getInputProps()}
            />
            <div className={styles.fileContainer}>
              <div
                className={styles.fileSelectedContainer}
                onClick={handleUploadCLick}
              >
                {value && !multiple
                  ? renderImage(value as File | string, 0)
                  : renderAddFileButton()}
              </div>
            </div>
            <div className={styles.label}>
              <h5>{label}</h5>
              <p>{translate(intl, 'VALIDATION.MAX_FILE_SIZE')}</p>
              <p>{translate(intl, 'VALIDATION.ALLOWED_EXTENSIONS')}</p>
              <p className={styles.selectedFileLabel}>
                {value && (
                  <FontAwesomeIcon
                    icon={faFileImport as IconProp}
                    fixedWidth
                    size="sm"
                    className={styles.selectedFileIcon}
                  />
                )}
                <span>
                  {value && value instanceof File
                    ? value.name
                    : asset?.fileName ??
                      translate(intl, 'VALIDATION.NO_FILE_SELECTED')}
                </span>
              </p>
              {errors &&
                errors.map((error, index) => (
                  <div className={styles.fileError} key={`file-error-${index}`}>
                    {error}
                  </div>
                ))}
              {helperText && <p>{helperText}</p>}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default FileInput;
