import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { GameRoom, GameRoomWithProfiles, Player } from '../../domain/GameRoom';
import { GameRoomActionTypes } from './actionTypes';

export type GameRoomStateType = {
  createdGameRoomLoading: boolean;
  createdGameRoomError: HttpError;
  createdGameRoomSuccess: boolean;
  createdGameRoom: GameRoomWithProfiles | null;
  gameRoom: GameRoom | null;
  gameRoomUpdateLoading: boolean;
  gameRoomUpdateSuccess: boolean;
  gameRoomUpdateError: null;
  gameRoomLoading: boolean;
  gameRoomError: HttpError;
  playerSequenceLoading: boolean;
  playerSequence: Player | null;
  playerSequenceError: HttpError;
  jokerTreasureGameRoom: GameRoom | null;
  jokerTreasureGameRoomCreateLoading: boolean;
  jokerTreasureGameRoomCreateError: HttpError;
  jokerTreasureGameRoomCreateSuccess: boolean;
  jokerTreasureCreatedGameRoom: GameRoom | null;
  gameRoomUpdateOnCardClickError: HttpError;
  gameRoomUpdateOnCardClickLoading: boolean;
  gameRoomUpdateOnCardClickSuccess: boolean;
};

export type GameRoomActionType = GameRoomStateType & {
  type: GameRoomActionTypes;
};

export const initialState: GameRoomStateType = {
  createdGameRoomLoading: false,
  createdGameRoomError: null,
  createdGameRoomSuccess: false,
  createdGameRoom: null,
  gameRoom: null,
  gameRoomUpdateLoading: false,
  gameRoomUpdateSuccess: false,
  gameRoomUpdateError: null,
  gameRoomLoading: false,
  gameRoomError: null,
  playerSequenceLoading: false,
  playerSequence: null,
  playerSequenceError: null,
  jokerTreasureGameRoom: null,
  jokerTreasureGameRoomCreateLoading: false,
  jokerTreasureGameRoomCreateError: null,
  jokerTreasureGameRoomCreateSuccess: false,
  jokerTreasureCreatedGameRoom: null,
  gameRoomUpdateOnCardClickError: null,
  gameRoomUpdateOnCardClickLoading: false,
  gameRoomUpdateOnCardClickSuccess: false,
};

const fetchGameRoomStart = (state: GameRoomStateType): GameRoomStateType => ({
  ...state,
  gameRoomLoading: true,
});

const fetchGameRoomSuccess = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  gameRoom: action.gameRoom,
  gameRoomLoading: false,
  gameRoomError: null,
});

const fetchGameRoomFail = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  gameRoomError: action.gameRoomError,
  gameRoomLoading: false,
});

const fetchPlayerSequenceStart = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  playerSequenceLoading: true,
});

const fetchPlayerSequenceSuccess = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  playerSequence: action.playerSequence,
  playerSequenceLoading: false,
  playerSequenceError: null,
});

const fetchPlayerSequenceFail = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  playerSequenceError: action.playerSequenceError,
  playerSequenceLoading: false,
});

const fetchCreatedGameRoomStart = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  createdGameRoomLoading: true,
  createdGameRoomSuccess: false,
});

const fetchCreatedGameRoomSuccess = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  createdGameRoomLoading: false,
  createdGameRoomError: null,
  createdGameRoomSuccess: true,
  gameRoom: action.createdGameRoom?.gameRoom || null,
  createdGameRoom: action.createdGameRoom || null,
});

const fetchCreatedGameRoomFail = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  createdGameRoomLoading: false,
  createdGameRoomError: action.createdGameRoomError,
});

const updateGameRoomStart = (state: GameRoomStateType): GameRoomStateType => ({
  ...state,
  gameRoomUpdateLoading: true,
});

const updateGameRoomSuccess = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  gameRoomUpdateLoading: false,
  gameRoomUpdateSuccess: true,
  gameRoom: action.gameRoom,
});

const updateGameRoomFail = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  gameRoomUpdateLoading: false,
  gameRoomUpdateError: action.gameRoomUpdateError,
});

const createJokerTreasureGameRoomStart = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  jokerTreasureGameRoomCreateLoading: true,
  jokerTreasureGameRoomCreateSuccess: false,
});

const createJokerTreasureGameRoomSuccess = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  jokerTreasureGameRoomCreateLoading: false,
  jokerTreasureGameRoomCreateError: null,
  jokerTreasureGameRoomCreateSuccess: true,
  jokerTreasureCreatedGameRoom: action.jokerTreasureCreatedGameRoom,
  jokerTreasureGameRoom: action.jokerTreasureCreatedGameRoom,
});

const createJokerTreasureGameRoomFail = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  jokerTreasureGameRoomCreateLoading: false,
  jokerTreasureGameRoomCreateError: action.jokerTreasureGameRoomCreateError,
});

const updateGameRoomOnCardClickStart = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  gameRoomUpdateOnCardClickLoading: true,
});

const updateGameRoomOnCardClickSuccess = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  gameRoomUpdateOnCardClickLoading: false,
  gameRoomUpdateOnCardClickSuccess: true,
  jokerTreasureGameRoom: action.jokerTreasureGameRoom,
});

const updateGameRoomOnCardClickFail = (
  state: GameRoomStateType,
  action: GameRoomActionType,
): GameRoomStateType => ({
  ...state,
  gameRoomUpdateOnCardClickLoading: false,
  gameRoomUpdateOnCardClickError: action.gameRoomUpdateOnCardClickError,
});

const resetJokerTreasureGameRoomCreateStore = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  jokerTreasureGameRoomCreateLoading: false,
  jokerTreasureGameRoomCreateError: null,
  jokerTreasureGameRoomCreateSuccess: false,
});

const resetUpdateGameRoomOnCardClickStore = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  gameRoomUpdateOnCardClickLoading: false,
  gameRoomUpdateError: null,
  gameRoomUpdateOnCardClickSuccess: false,
});

const resetGameRoomCreateStore = (
  state: GameRoomStateType,
): GameRoomStateType => ({
  ...state,
  createdGameRoomLoading: false,
  createdGameRoomError: null,
  createdGameRoomSuccess: false,
});

const resetGameRoomStore = (): GameRoomStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: GameRoomActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_GAME_ROOM_START:
      return fetchGameRoomStart(state);
    case actionTypes.FETCH_GAME_ROOM_SUCCESS:
      return fetchGameRoomSuccess(state, action);
    case actionTypes.FETCH_GAME_ROOM_FAIL:
      return fetchGameRoomFail(state, action);
    case actionTypes.FETCH_PLAYER_SEQUENCE_START:
      return fetchPlayerSequenceStart(state);
    case actionTypes.FETCH_PLAYER_SEQUENCE_SUCCESS:
      return fetchPlayerSequenceSuccess(state, action);
    case actionTypes.FETCH_PLAYER_SEQUENCE_FAIL:
      return fetchPlayerSequenceFail(state, action);
    case actionTypes.FETCH_CREATED_GAME_ROOM_START:
      return fetchCreatedGameRoomStart(state);
    case actionTypes.FETCH_CREATED_GAME_ROOM_SUCCESS:
      return fetchCreatedGameRoomSuccess(state, action);
    case actionTypes.FETCH_CREATED_GAME_ROOM_FAIL:
      return fetchCreatedGameRoomFail(state, action);
    case actionTypes.UPDATE_GAME_ROOM_START:
      return updateGameRoomStart(state);
    case actionTypes.UPDATE_GAME_ROOM_SUCCESS:
      return updateGameRoomSuccess(state, action);
    case actionTypes.UPDATE_GAME_ROOM_FAIL:
      return updateGameRoomFail(state, action);
    case actionTypes.CREATE_JOKER_TREASURE_GAME_ROOM_START:
      return createJokerTreasureGameRoomStart(state);
    case actionTypes.CREATE_JOKER_TREASURE_GAME_ROOM_SUCCESS:
      return createJokerTreasureGameRoomSuccess(state, action);
    case actionTypes.CREATE_JOKER_TREASURE_GAME_ROOM_FAIL:
      return createJokerTreasureGameRoomFail(state, action);
    case actionTypes.UPDATE_GAME_ROOM_ON_CARD_CLICK_START:
      return updateGameRoomOnCardClickStart(state);
    case actionTypes.UPDATE_GAME_ROOM_ON_CARD_CLICK_SUCCESS:
      return updateGameRoomOnCardClickSuccess(state, action);
    case actionTypes.UPDATE_GAME_ROOM_ON_CARD_CLICK_FAIL:
      return updateGameRoomOnCardClickFail(state, action);
    case actionTypes.RESET_JOKER_TREASURE_GAME_ROOM_CREATE_STORE:
      return resetJokerTreasureGameRoomCreateStore(state);
    case actionTypes.RESET_UPDATE_GAME_ROOM_ON_CARD_CLICK_STORE:
      return resetUpdateGameRoomOnCardClickStore(state);
    case actionTypes.RESET_GAME_ROOM_CREATE_STORE:
      return resetGameRoomCreateStore(state);
    case actionTypes.RESET_GAME_ROOM_STORE:
      return resetGameRoomStore();
    default:
      return state;
  }
};

export default reducer;
