export const FETCH_AUTHORIZATION_COUNTRIES_START =
  'FETCH_AUTHORIZATION_COUNTRIES_START';
export const FETCH_AUTHORIZATION_COUNTRIES_SUCCESS =
  'FETCH_AUTHORIZATION_COUNTRIES_SUCCESS';
export const FETCH_AUTHORIZATION_COUNTRIES_FAIL =
  'FETCH_AUTHORIZATION_COUNTRIES_FAIL';

export const TOGGLE_AUTHORIZATION_COUNTRY_START =
  'TOGGLE_AUTHORIZATION_COUNTRY_START';
export const TOGGLE_AUTHORIZATION_COUNTRY_SUCCESS =
  'TOGGLE_AUTHORIZATION_COUNTRY_SUCCESS';
export const TOGGLE_AUTHORIZATION_COUNTRY_FAIL =
  'TOGGLE_AUTHORIZATION_COUNTRY_FAIL';

export const AUTHORIZE_COUNTRY_START = 'AUTHORIZE_COUNTRY_START';
export const AUTHORIZE_COUNTRY_SUCCESS = 'AUTHORIZE_COUNTRY_SUCCESS';
export const AUTHORIZE_COUNTRY_FAIL = 'AUTHORIZE_COUNTRY_FAIL';

export const RESET_AUTHORIZATION_COUNTRY_STORE =
  'RESET_AUTHORIZATION_COUNTRY_STORE';

export const COMPLETELY_RESET_AUTHORIZATION_COUNTRY_STORE =
  'COMPLETELY_RESET_AUTHORIZATION_COUNTRY_STORE';

export type AuthorizationCountryActionTypes =
  | typeof FETCH_AUTHORIZATION_COUNTRIES_START
  | typeof FETCH_AUTHORIZATION_COUNTRIES_SUCCESS
  | typeof FETCH_AUTHORIZATION_COUNTRIES_FAIL
  | typeof AUTHORIZE_COUNTRY_START
  | typeof AUTHORIZE_COUNTRY_SUCCESS
  | typeof AUTHORIZE_COUNTRY_FAIL
  | typeof TOGGLE_AUTHORIZATION_COUNTRY_START
  | typeof TOGGLE_AUTHORIZATION_COUNTRY_SUCCESS
  | typeof TOGGLE_AUTHORIZATION_COUNTRY_FAIL
  | typeof RESET_AUTHORIZATION_COUNTRY_STORE
  | typeof COMPLETELY_RESET_AUTHORIZATION_COUNTRY_STORE;
