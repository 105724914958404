import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './ResetPasswordModal.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { ResetPasswordRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import Modal from '../../../../common/Modal/Modal';
import { User } from '../../../../domain/User';

type Props = {
  onPasswordReset: (inputs: ResetPasswordRequest) => void;
  isLoading: boolean;
  error: HttpError;
  code: string;
  isOpen: boolean;
  onClose: () => void;
  validatedUser: User;
};

type FormInputs = {
  password: string;
  passwordRepeat: string;
};

export const PasswordResetForm = ({
  onPasswordReset,
  isLoading,
  error,
  code,
  isOpen,
  onClose,
  validatedUser,
}: Props) => {
  const intl = useIntl();

  const INPUTS = [
    {
      name: 'password',
      label: translate(intl, 'PASSWORD_RESET_FORM.PASSWORD'),
      type: 'password',
      required: true,
      placeholder: translate(intl, 'PASSWORD_RESET_FORM.PASSWORD_PLACEHOLDER'),
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 8,
        },
        {
          type: 'maxLength',
          parameter: 30,
        },
      ],
    },
    {
      name: 'passwordRepeat',
      label: translate(intl, 'PASSWORD_RESET_FORM.PASSWORD_REPEAT'),
      type: 'password',
      required: true,
      placeholder: translate(
        intl,
        'PASSWORD_RESET_FORM.PLACHEHOLDER_PASSWORD_REPEAT',
      ),
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 8,
        },
        {
          type: 'maxLength',
          parameter: 30,
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onPasswordReset({
      password: submitInputs.password,
      passwordRepeat: submitInputs.passwordRepeat,
      email: validatedUser.email,
      code: code,
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      className={styles.modalContainer}
      title={translate(intl, 'PASSWORD_RESET_FORM.TITLE')}
    >
      <div className={styles.passwordResetForm}>
        <Form
          className={styles.form}
          error={error}
          onSubmit={onSubmit}
          scrollIntoView={false}
        >
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {translate(intl, globalError)}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              required={input.required}
              onInputBlur={onInputBlur}
              placeholder={input.placeholder}
              inputLabelProps={{
                shrink: true,
                sx: {
                  marginTop: '-1rem',
                },
              }}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'PASSWORD_RESET_FORM.SUBMIT')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.resetPasswordError,
  isLoading: state.auth.resetPasswordLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onPasswordReset: (inputs: ResetPasswordRequest) =>
    dispatch(authService.resetPassword(inputs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
