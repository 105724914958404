import { HttpError } from '../../config/Axios/axios-instance';
import _ from 'lodash';
import { IntlShape } from 'react-intl';
import { translate } from '../messageTranslator/translate';

export const getGlobalError = (error: HttpError, intl: IntlShape) => {
  if (_.isArray(error)) {
    return error
      .map((message) => translate(intl, message.toString(), message.toString()))
      .join(' ');
  }

  return error?.toString();
};

export const getHttpError = (error: HttpError | undefined, intl: IntlShape) => {
  if (!error) {
    return null;
  }

  return translate(intl, error.toString(), error.toString());
};
