import axios from '../../config/Axios/axios-instance';
import {
  fetchAuthorizationCountriesFail,
  fetchAuthorizationCountriesStart,
  fetchAuthorizationCountriesSuccess,
  toggleAuthorizationCountryFail,
  toggleAuthorizationCountryStart,
  toggleAuthorizationCountrySuccess,
  authorizeCountryStart,
  authorizeCountrySuccess,
  authorizeCountryFail,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';

const API_URL = '/authorization-countries';

export const fetchAuthorizationCountries =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchAuthorizationCountriesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchAuthorizationCountriesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchAuthorizationCountriesFail(err?.response?.data?.error));
      });
  };

export const toggleAuthorizationCountry =
  (id: number) => (dispatch: Dispatch) => {
    dispatch(toggleAuthorizationCountryStart());
    return axios
      .patch(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(toggleAuthorizationCountrySuccess(response.data));
      })
      .catch((err) => {
        dispatch(toggleAuthorizationCountryFail(err?.response?.data?.error));
      });
  };

export const authorizeCountry = () => (dispatch: Dispatch) => {
  dispatch(authorizeCountryStart());
  return axios
    .get(`${API_URL}/authorize`)
    .then(() => {
      dispatch(authorizeCountrySuccess());
    })
    .catch((err) => {
      dispatch(authorizeCountryFail(err?.response?.data?.error));
    });
};
