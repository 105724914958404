import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Category } from '../../domain/Category';
import { CategoryActionTypes } from './actionTypes';

export type CategoryStateType = {
  categories: Category[] | null;
  categoriesLoading: boolean;
  categoriesError: HttpError;
  publicCategories: Category[] | null;
  publicCategoriesLoading: boolean;
  publicCategoriesError: HttpError;
  categoriesUpdateNeeded: boolean;
};

export type CategoryActionType = CategoryStateType & {
  type: CategoryActionTypes;
};

export const initialState: CategoryStateType = {
  categories: null,
  categoriesLoading: true,
  categoriesError: null,
  publicCategories: null,
  publicCategoriesLoading: true,
  publicCategoriesError: null,
  categoriesUpdateNeeded: false,
};

const fetchCategoriesStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoriesLoading: true,
});

const fetchCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categories: action.categories,
  categoriesLoading: false,
  categoriesError: null,
  categoriesUpdateNeeded: false,
});

const fetchCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesError: action.categoriesError,
  categoriesLoading: false,
});

const fetchPublicCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  publicCategoriesLoading: true,
});

const fetchPublicCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  publicCategories: action.publicCategories,
  publicCategoriesLoading: false,
  publicCategoriesError: null,
});

const fetchPublicCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  publicCategoriesError: action.publicCategoriesError,
  publicCategoriesLoading: false,
});

const resetCategoryStore = (): CategoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CategoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_START:
      return fetchCategoriesStart(state);
    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      return fetchCategoriesSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_FAIL:
      return fetchCategoriesFail(state, action);
    case actionTypes.FETCH_PUBLIC_CATEGORIES_START:
      return fetchPublicCategoriesStart(state);
    case actionTypes.FETCH_PUBLIC_CATEGORIES_SUCCESS:
      return fetchPublicCategoriesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_CATEGORIES_FAIL:
      return fetchPublicCategoriesFail(state, action);
    case actionTypes.RESET_CATEGORY_STORE:
      return resetCategoryStore();
    case actionTypes.LOGOUT:
      return resetCategoryStore();
    default:
      return state;
  }
};

export default reducer;
