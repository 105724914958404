import React, { ReactNode, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { routes } from '../../config/Router/routes';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import PublicNavigation from '../PublicNavigation/PublicNavigation';
import Footer from '../Footer/Footer';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { Roles } from '../../domain/Role';
import Navigation from '../Navigation/Navigation';
import Sidebar from '../Sidebar/Sidebar';
import cx from 'classnames';
import styles from './Layout.module.scss';
import {
  faUsers,
  faGlobe,
  faLanguage,
  faReceipt,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  isValidated: boolean;
};

export type NavigationItem = {
  label: string;
  to?: string;
  icon?: IconProp;
  anchor?: string;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({ children, isAuthenticated, isValidated }: Props) => {
  const IGNORED_ROUTES = ['password-reset', 'registration-confirmation'];

  const location = useLocation();
  const intl = useIntl();
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    window.scroll({ top: 0 });
  }, [location.key]);

  const getFaqLabel = () => {
    if (width && width < MOBILE_BREAK_POINT) {
      return translate(intl, 'NAVIGATION.FAQ_FULL');
    }

    return translate(intl, 'NAVIGATION.FAQ');
  };

  const getNavigationItems = () => {
    return [
      {
        label: translate(intl, 'NAVIGATION.GAMES'),
        to: routes.publicGames,
      },
      {
        label: translate(intl, 'NAVIGATION.WHY_US'),
        anchor: 'whyUs',
      },
      {
        label: getFaqLabel(),
        anchor: 'faq',
      },
    ];
  };

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_PLAYER'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PLAYERS'),
          to: routes.players.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faLanguage as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COUNTRIES'),
          to: routes.authorizationCountries,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CASHOUTS'),
          to: routes.cashouts.list,
          icon: faReceipt as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_GAMES'),
          to: routes.games.list,
          icon: faGamepad as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  const isRouteIgnored =
    IGNORED_ROUTES.find((ignoredRoute) =>
      location.pathname.includes(ignoredRoute),
    ) ||
    location.pathname === routes.admin ||
    location.pathname === routes.admin + '/';

  if (!isValidated || isRouteIgnored) {
    return <>{children}</>;
  }

  if (
    !isAuthenticated ||
    !location.pathname.includes(routes.admin) ||
    isRouteIgnored
  ) {
    return (
      <div className={styles.publicContainer}>
        <PublicNavigation navigationItems={getNavigationItems()} />
        {children}
        <Footer />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar navigationGroups={SIDEBAR_ITEMS} />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {SIDEBAR_ITEMS.map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Layout;
