import React, { useEffect, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { NavigationItem } from '../Layout/Layout';
import logo from '../../assets/GubyGame.svg';
import Button from '../Button/Button';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import user from '../../assets/user.svg';
import { ReactSVG } from 'react-svg';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import HamburgerButton from './HamburgerButton/HamburgerButton';
import starIcon from '../../assets/star.svg';
import cx from 'classnames';
import rightArrow from '../../assets/rightArrow.svg';
import { scroller } from 'react-scroll';
// import { useWeb3Modal } from '@web3modal/wagmi/react'; uncomment if wallet login needed
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import AuthModals from './AuthModals/AuthModals';
import SuccessModal from '../SuccessModal/SuccessModal';
import ResetPasswordModal from '../../component/Public/Auth/ResetPasswordModal/ResetPasswordModal';
import ManualDepositModal from '../../component/Profile/ManualDepositModal/ManualDepositModal';
import { ThunkDispatch } from 'redux-thunk';
import * as userService from '../../store/user/service';
import { AnyAction } from 'redux';
import VerificationModal from '../../component/Public/Auth/VerificationModal/VerificationModal';

export type Props = {
  navigationItems: NavigationItem[];
  isAuthenticated: boolean;
  currentUser: User | null;
  isRemindPasswordSuccess: boolean;
  isRegistrationSuccess: boolean;
  validateCodeSuccess: boolean;
  validatedUser: User | null;
  refCode?: string;
  onFetchRefCodeUsername: (refCode: string) => void;
  refOwner?: string | null;
  isSuccess: boolean;
};

const MOBILE_BREAK_POINT = 1400;

export const PublicNavigation = ({
  navigationItems,
  isAuthenticated,
  currentUser,
  isRegistrationSuccess,
  isRemindPasswordSuccess,
  validateCodeSuccess,
  validatedUser,
  refCode,
  onFetchRefCodeUsername,
  refOwner,
  isSuccess,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const [isDepositModalOpened, setIsDepositModalOpened] =
  //   useState<boolean>(false); uncomment if direct deposit needed
  const [isManualDepositModalOpened, setIsManualDepositModalOpened] =
    useState<boolean>(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isSuccessRemindPasswordModalOpen, setSuccessRemindPasswordModalOpen] =
    useState<boolean>(false);
  const [isSuccessRegistrationModalOpen, setSuccessRegistrationModalOpen] =
    useState<boolean>(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState<boolean>(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoginModalOpen(false);
      setIsVerificationModalOpen(false);
      navigate(routes.profile);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isSuccess) {
      setIsLoginModalOpen(false);
      setIsVerificationModalOpen(true);
    }
  }, [isSuccess]);

  const intl = useIntl();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  // const { open } = useWeb3Modal(); uncomment if wallet login needed
  const location = useLocation();
  const code = localStorage.getItem('code');

  useEffect(() => {
    if (refCode) {
      onFetchRefCodeUsername(refCode);
    }
  }, [refCode]);

  useEffect(() => {
    if (isRegistrationSuccess) {
      setSuccessRegistrationModalOpen(true);
    }
  }, [isRegistrationSuccess]);

  useEffect(() => {
    if (isRemindPasswordSuccess) {
      setSuccessRemindPasswordModalOpen(true);
    }
  }, [isRemindPasswordSuccess]);

  useEffect(() => {
    if (validateCodeSuccess && code) {
      setIsResetPasswordModalOpen(true);
    }
  }, [validateCodeSuccess]);

  const handleClick = (item: NavigationItem) => {
    if (!item.anchor && item.to) {
      navigate(item.to);
      return;
    }

    if (location.pathname !== routes.homepage) {
      navigate(routes.homepage, { state: item.anchor });
      return;
    }

    if (item.anchor) {
      scroller.scrollTo(item.anchor, {
        smooth: true,
        duration: 300,
      });
    }
  };

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  const getButtons = () => {
    if (isAuthenticated) {
      return (
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.balance}
            onClick={() => setIsManualDepositModalOpened(true)}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: translate(intl, 'NAVIGATION.BALANCE').replace(
                  ':amount',
                  (currentUser?.profile?.balance.toFixed(2) || 0).toString(),
                ),
              }}
            />
          </Button>
          <Button className={styles.vip} onClick={() => navigate(routes.vip)}>
            <>
              <ReactSVG src={starIcon} className={styles.vipIcon} />
              {currentUser?.profile?.isVip
                ? translate(intl, 'NAVIGATION.VIP')
                : translate(intl, 'NAVIGATION_BECOME_VIP')}
            </>
          </Button>
          {!isAuthenticated && (
            <Button
              className={styles.loginButton}
              onClick={() => setIsLoginModalOpen(true)}
            >
              <ReactSVG src={user} />
              {translate(intl, 'NAVIGATION.LOGIN_BUTTON')}
            </Button>
          )}
          {/* uncomment if wallet login needed */}
          {/* {!isAuthenticated && (
            <Button className={styles.loginButton} onClick={() => open()}>
              <ReactSVG src={user} />
              {translate(intl, 'NAVIGATION.LOGIN_BUTTON')}
            </Button>
          )} */}

          {isAuthenticated && (
            <Button
              className={styles.profileButton}
              onClick={() => navigate(routes.profile)}
            >
              <ReactSVG src={user} />
              {translate(intl, 'NAVIGATION.PROFILE')}
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className={styles.buttonsContainer}>
        {!isAuthenticated && (
          <Button
            className={styles.loginButton}
            onClick={() => setIsLoginModalOpen(true)}
          >
            <ReactSVG src={user} />
            {translate(intl, 'NAVIGATION.LOGIN_BUTTON')}
          </Button>
        )}
        {/* uncomment if wallet login needed */}
        {/* {!isAuthenticated && (
          <Button className={styles.loginButton} onClick={() => open()}>
            <ReactSVG src={user} />
            {translate(intl, 'NAVIGATION.LOGIN_BUTTON')}
          </Button>
        )} */}
        {isAuthenticated && (
          <Button
            className={styles.profileButton}
            onClick={() => navigate(routes.profile)}
          >
            <ReactSVG src={user} />
            {translate(intl, 'NAVIGATION.PROFILE')}
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <header className={styles.navigationContainer}>
        <div
          className={cx(styles.leftContainer, {
            [styles.loggedIn]: isAuthenticated,
          })}
        >
          <div className={styles.logo}>
            <Link to={routes.homepage}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>

        <div className={styles.rightContainer}>
          {width && width < MOBILE_BREAK_POINT && (
            <HamburgerButton
              isMobileMenuOpen={isMobileMenuOpen}
              clickHandler={() =>
                setIsMobileMenuOpen((prevState) => !prevState)
              }
            />
          )}
          {width && width > MOBILE_BREAK_POINT && (
            <>
              <div className={styles.navigationItems}>
                {navigationItems.map((item) => (
                  <div key={item.label} onClick={() => handleClick(item)}>
                    {item.label}
                  </div>
                ))}
              </div>
              {getButtons()}
            </>
          )}
          {width && width < MOBILE_BREAK_POINT && isMobileMenuOpen && (
            <div className={styles.mobileMenuLinks}>
              {navigationItems.map((item, index) => (
                <div
                  key={item.label}
                  className={cx(styles.link, {
                    [styles.isLast]: index === navigationItems.length - 1,
                  })}
                  onClick={() => handleClick(item)}
                >
                  <>
                    {item.label}
                    <ReactSVG src={rightArrow} />
                  </>
                </div>
              ))}
              {getButtons()}
            </div>
          )}
        </div>
        {/* {isDepositModalOpened && (
        <DepositModal
          isOpen={isDepositModalOpened}
          onClose={() => setIsDepositModalOpened(false)}
        /> 
      ) uncomment if direct deposit needed */}
        {isManualDepositModalOpened && (
          <ManualDepositModal
            isOpen={isManualDepositModalOpened}
            onClose={() => setIsManualDepositModalOpened(false)}
          />
        )}
        {isSuccessRemindPasswordModalOpen && (
          <SuccessModal
            onClose={() => setSuccessRemindPasswordModalOpen(false)}
            isOpen={isSuccessRemindPasswordModalOpen}
            title={translate(
              intl,
              'REMIND_PASSWORD.SUCCESS_REMIND_PASSWORD_MODAL_TITLE',
            )}
            buttonText={translate(
              intl,
              'REMIND_PASSWORD.SUCCESS_REMIND_PASSWORD_MODAL_BUTTON',
            )}
            description={translate(
              intl,
              'REMIND_PASSWORD.SUCCESS_REMIND_PASSWORD_MODAL_DESCRIPTION',
            )}
          />
        )}
        {isSuccessRegistrationModalOpen && (
          <SuccessModal
            onClose={() => setSuccessRegistrationModalOpen(false)}
            isOpen={isSuccessRegistrationModalOpen}
            title={translate(
              intl,
              'REGISTRATION.SUCCESS_REGISTRATION_MODAL_TITLE',
            )}
            buttonText={translate(
              intl,
              'REGISTRATION.SUCCESS_REGISTRATION_MODAL_BUTTON',
            )}
            description={translate(
              intl,
              'REGISTRATION.SUCCESS_REGISTRATION_MODAL_DESCRIPTION',
            )}
          />
        )}
        {isResetPasswordModalOpen && code && validatedUser && (
          <ResetPasswordModal
            isOpen={isResetPasswordModalOpen}
            onClose={() => setIsResetPasswordModalOpen(false)}
            code={code}
            validatedUser={validatedUser}
          />
        )}

        {isVerificationModalOpen && (
          <VerificationModal
            isOpen={isVerificationModalOpen}
            onClose={() => setIsVerificationModalOpen(false)}
          />
        )}
      </header>
      <AuthModals
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
        setIsResetPasswordModalOpen={setIsResetPasswordModalOpen}
        refOwner={refOwner}
      />
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.user.currentUser,
  isRegistrationSuccess: state.auth.registrationSuccess,
  isRemindPasswordSuccess: state.auth.isRemindSuccess,
  validatedUser: state.auth.validatedUser,
  validateCodeSuccess: state.auth.validateCodeSuccess,
  refOwner: state.user.refOwner,
  refCode: state.auth.refCode,
  isSuccess: state.auth.loginVerificationSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchRefCodeUsername: (refCode: string) =>
    dispatch(userService.getUsernameByRefCode(refCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
