export const FETCH_PLAYING_HISTORY_START = 'FETCH_PLAYING_HISTORY_START';
export const FETCH_PLAYING_HISTORY_SUCCESS = 'FETCH_PLAYING_HISTORY_SUCCESS';
export const FETCH_PLAYING_HISTORY_FAIL = 'FETCH_PLAYING_HISTORY_FAIL';

export const FETCH_PLAYER_PLAYING_HISTORY_START =
  'FETCH_PLAYER_PLAYING_HISTORY_START';
export const FETCH_PLAYER_PLAYING_HISTORY_SUCCESS =
  'FETCH_PLAYER_PLAYING_HISTORY_SUCCESS';
export const FETCH_PLAYER_PLAYING_HISTORY_FAIL =
  'FETCH_PLAYER_PLAYING_HISTORY_FAIL';

export const RESET_PLAYING_HISTORY_STORE = 'RESET_PLAYING_HISTORY_STORE';

export const LOGOUT = 'LOGOUT';

export type PlayingHistoryActionTypes =
  | typeof FETCH_PLAYING_HISTORY_START
  | typeof FETCH_PLAYING_HISTORY_SUCCESS
  | typeof FETCH_PLAYING_HISTORY_FAIL
  | typeof FETCH_PLAYER_PLAYING_HISTORY_START
  | typeof FETCH_PLAYER_PLAYING_HISTORY_SUCCESS
  | typeof FETCH_PLAYER_PLAYING_HISTORY_FAIL
  | typeof RESET_PLAYING_HISTORY_STORE
  | typeof LOGOUT;
