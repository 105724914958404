import * as actionTypes from './actionTypes';
import { GameActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Game } from '../../domain/Game';
import { PublicGame } from '../../domain/PublicGame';

export type GameStateType = {
  gamesList: ListResults<Game> | null;
  gamesListLoading: boolean;
  gamesListError: HttpError;
  publicGames: ListResults<PublicGame> | null;
  publicGamesLoading: boolean;
  publicGamesError: HttpError;
  publicGame: PublicGame | null;
  publicGameLoading: boolean;
  publicGameError: HttpError;
  gamesListUpdateNeeded: boolean;
  gameCreateLoading: boolean;
  gameCreateError: HttpError;
  gameCreateSuccess: boolean;
  createdGame: Game | null;
  gameUpdateLoading: boolean;
  gameUpdateError: HttpError;
  gameUpdateSuccess: boolean;
  game: Game | null;
  gameLoading: boolean;
  gameError: HttpError;
  gameDeleteLoading: boolean;
  gameDeleteError: HttpError;
  gameDeleteSuccess: boolean;
  gamePriorityUpdateLoading: boolean;
  gamePriorityUpdateSuccess: boolean;
  gamePriorityUpdateError: HttpError;
};

export type GameActionType = GameStateType & {
  type: GameActionTypes;
};

export const initialState: GameStateType = {
  gamesList: null,
  gamesListLoading: true,
  gamesListError: null,
  publicGames: null,
  publicGamesLoading: false,
  publicGamesError: null,
  publicGame: null,
  publicGameLoading: false,
  publicGameError: null,
  gamesListUpdateNeeded: false,
  gameCreateLoading: false,
  gameCreateError: null,
  gameCreateSuccess: false,
  createdGame: null,
  gameUpdateLoading: false,
  gameUpdateError: null,
  gameUpdateSuccess: false,
  game: null,
  gameLoading: false,
  gameError: null,
  gameDeleteLoading: false,
  gameDeleteError: null,
  gameDeleteSuccess: false,
  gamePriorityUpdateLoading: false,
  gamePriorityUpdateSuccess: false,
  gamePriorityUpdateError: null,
};

const fetchGamesListStart = (state: GameStateType): GameStateType => ({
  ...state,
  gamesListLoading: true,
});

const fetchGamesListSuccess = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gamesListLoading: false,
  gamesList: action.gamesList,
  gamesListUpdateNeeded: false,
});

const fetchGamesListFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gamesListLoading: false,
  gamesListError: action.gamesListError,
});

const fetchPublicGamesStart = (state: GameStateType): GameStateType => ({
  ...state,
  publicGamesLoading: true,
});

const fetchPublicGamesSuccess = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  publicGamesLoading: false,
  publicGames: action.publicGames,
});

const fetchPublicGamesFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  publicGamesLoading: false,
  publicGamesError: action.gamesListError,
});

const fetchPublicGameStart = (state: GameStateType): GameStateType => ({
  ...state,
  publicGameLoading: true,
});

const fetchPublicGameSuccess = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  publicGameLoading: false,
  publicGame: action.publicGame,
});

const fetchPublicGameFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  publicGameLoading: false,
  publicGameError: action.gamesListError,
});

const fetchGameStart = (state: GameStateType): GameStateType => ({
  ...state,
  gameLoading: true,
});

const fetchGameSuccess = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gameLoading: false,
  game: action.game,
});

const fetchGameFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gameLoading: false,
  gameError: action.gameError,
});

const createGameStart = (state: GameStateType): GameStateType => ({
  ...state,
  gameCreateLoading: true,
});

const createGameSuccess = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gameCreateLoading: false,
  gameCreateError: null,
  gameCreateSuccess: true,
  gamesListUpdateNeeded: true,
  createdGame: action.createdGame,
});

const createGameFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gameCreateLoading: false,
  gameCreateError: action.gameCreateError,
});

const updateGameStart = (state: GameStateType): GameStateType => ({
  ...state,
  gameUpdateLoading: true,
});

const updateGameSuccess = (state: GameStateType): GameStateType => ({
  ...state,
  gameUpdateLoading: false,
  gameUpdateSuccess: true,
  gamesListUpdateNeeded: true,
});

const updateGameFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gameUpdateLoading: false,
  gameUpdateError: action.gameUpdateError,
});

const deleteGameStart = (state: GameStateType): GameStateType => ({
  ...state,
  gameDeleteLoading: true,
});

const deleteGameSuccess = (state: GameStateType): GameStateType => ({
  ...state,
  gameDeleteLoading: false,
  gameDeleteSuccess: true,
  gamesListUpdateNeeded: true,
});

const deleteGameFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gameDeleteLoading: false,
  gameDeleteError: action.gameDeleteError,
});

const updateGamePriorityStart = (state: GameStateType): GameStateType => ({
  ...state,
  gamePriorityUpdateLoading: true,
  gamePriorityUpdateSuccess: false,
});

const updateGamePrioritySuccess = (state: GameStateType): GameStateType => ({
  ...state,
  gamePriorityUpdateError: null,
  gamePriorityUpdateSuccess: true,
  gamesListUpdateNeeded: true,
});

const updateGamePriorityFail = (
  state: GameStateType,
  action: GameActionType,
): GameStateType => ({
  ...state,
  gamePriorityUpdateLoading: false,
  gamePriorityUpdateError: action.gamePriorityUpdateError,
});

const resetGameStore = (): GameStateType => ({
  ...initialState,
});

const completelyResetGameStore = (): GameStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: GameActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_GAMES_LIST_START:
      return fetchGamesListStart(state);
    case actionTypes.FETCH_GAMES_LIST_SUCCESS:
      return fetchGamesListSuccess(state, action);
    case actionTypes.FETCH_GAMES_LIST_FAIL:
      return fetchGamesListFail(state, action);
    case actionTypes.FETCH_PUBLIC_GAMES_START:
      return fetchPublicGamesStart(state);
    case actionTypes.FETCH_PUBLIC_GAMES_SUCCESS:
      return fetchPublicGamesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_GAMES_FAIL:
      return fetchPublicGamesFail(state, action);
    case actionTypes.FETCH_PUBLIC_GAME_START:
      return fetchPublicGameStart(state);
    case actionTypes.FETCH_PUBLIC_GAME_SUCCESS:
      return fetchPublicGameSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_GAME_FAIL:
      return fetchPublicGameFail(state, action);
    case actionTypes.FETCH_GAME_START:
      return fetchGameStart(state);
    case actionTypes.FETCH_GAME_SUCCESS:
      return fetchGameSuccess(state, action);
    case actionTypes.FETCH_GAME_FAIL:
      return fetchGameFail(state, action);
    case actionTypes.CREATE_GAME_START:
      return createGameStart(state);
    case actionTypes.CREATE_GAME_SUCCESS:
      return createGameSuccess(state, action);
    case actionTypes.CREATE_GAME_FAIL:
      return createGameFail(state, action);
    case actionTypes.UPDATE_GAME_START:
      return updateGameStart(state);
    case actionTypes.UPDATE_GAME_SUCCESS:
      return updateGameSuccess(state);
    case actionTypes.UPDATE_GAME_FAIL:
      return updateGameFail(state, action);
    case actionTypes.DELETE_GAME_START:
      return deleteGameStart(state);
    case actionTypes.DELETE_GAME_SUCCESS:
      return deleteGameSuccess(state);
    case actionTypes.DELETE_GAME_FAIL:
      return deleteGameFail(state, action);
    case actionTypes.UPDATE_GAME_PRIORITY_START:
      return updateGamePriorityStart(state);
    case actionTypes.UPDATE_GAME_PRIORITY_SUCCESS:
      return updateGamePrioritySuccess(state);
    case actionTypes.UPDATE_GAME_PRIORITY_FAIL:
      return updateGamePriorityFail(state, action);
    case actionTypes.RESET_GAME_STORE:
      return resetGameStore();
    case actionTypes.LOGOUT:
      return completelyResetGameStore();
    default:
      return state;
  }
};

export default reducer;
